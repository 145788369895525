import React, { useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { registerRequest } from "../../../core/redux/account/slice";


const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };


  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
    },
    onSubmit: (values) => {
      dispatch(registerRequest({ values, navigate }));
    },
  });

  const route = all_routes;
  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper register-wrap bg-img">
          <div className="login-content">
            <form action="signin">
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                </div>
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt />
                </Link>
                <div className="login-userheading">
                  <h3>Registrar</h3>
                  <h4>Crear una nueva cuenta</h4>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-login">
                      <label>Nombre</label>
                      <div className="form-addons">
                        <input type="text" className="form-control"
                          name="firstName"
                          onChange={formik.handleChange}
                          value={formik.values.firstName} />
                        <ImageWithBasePath
                          src="assets/img/icons/user-icon.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-login">
                      <label>Apellido</label>
                      <div className="form-addons">
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/user-icon.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-login">
                      <label>Correo electrónico</label>
                      <div className="form-addons">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          onChange={(e) => {
                            formik.handleChange(e);
                            const emailValue = e.target.value;
                            const userNameValue = emailValue.split('@')[0];
                            formik.setFieldValue('userName', userNameValue);
                          }}
                          value={formik.values.email}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/mail.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-login">
                      <label>Usuario</label>
                      <div className="form-addons">
                        <input
                          type="text"
                          className="form-control"
                          name="userName"
                          onChange={formik.handleChange}
                          value={formik.values.userName}
                        />
                        <ImageWithBasePath
                          src="assets/img/icons/user-icon.svg"
                          alt="img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-login">
                  <label>Contraseña</label>
                  <div className="pass-group">
                    <input
                      type={passwordVisibility.password ? "text" : "password"}
                      className="form-control"
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <span
                      className={`fas toggle-password ${passwordVisibility.password ? "fa-eye" : "fa-eye-slash"
                        }`}
                      onClick={() => togglePasswordVisibility("password")}
                    ></span>
                  </div>
                </div>
                <div className="form-login">
                  <label>Confirmar Contraseña</label>
                  <div className="pass-group">
                    <input
                      type={
                        passwordVisibility.confirmPassword ? "text" : "password"
                      }
                      className="form-control"
                      name="confirmPassword"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                    />
                    <span
                      className={`fas toggle-password ${passwordVisibility.confirmPassword
                        ? "fa-eye"
                        : "fa-eye-slash"
                        }`}
                      onClick={() =>
                        togglePasswordVisibility("confirmPassword")
                      }
                    ></span>
                  </div>
                </div>
                <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="custom-control custom-checkbox justify-content-start">
                        <div className="custom-control custom-checkbox">
                          <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                            <input type="checkbox" />
                            <span className="checkmarks" />Acepto los {" "}
                            <Link to="#" className="hover-a">
                              Terminos &amp; Condiciones
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-login">
                  <Link onClick={formik.handleSubmit} className="btn btn-login">
                    Registar
                  </Link>
                </div>
                <div className="signinform">
                  <h4>
                    ¿Ya tienes una cuenta ?{" "}
                    <Link to={route.signin} className="hover-a">
                      Iniciar sesión
                    </Link>
                  </h4>
                </div>
                {/* <div className="form-setlogin or-text">
                  <h4>OR</h4>
                </div> */}
                {/* <div className="form-sociallink">
                  <ul className="d-flex">
                    <li>
                      <Link to="#" className="facebook-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/facebook-logo.svg"
                          alt="Facebook"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/icons/google.png"
                          alt="Google"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="apple-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/apple-logo.svg"
                          alt="Apple"
                        />
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                  <p>Copyright © {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
