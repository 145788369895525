import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import ImageWithBasePath from '../../core/img/imagewithbasebath';
import { connect } from 'react-redux';
import { searchRequest, scanCodeRequest } from '../../core/redux/mainproducts/slice';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';
import { get } from '../../core/helpers/apiHelpers';
import toast from 'react-hot-toast';
import { Modal } from 'react-bootstrap';
import NumericInput from '../components/NumericInput';
import { Button } from 'antd';

const ProductSearch = ({ data, search, onSelect }) => {
    const [query, setQuery] = useState('');
    const [scannerEnabled, setScannerEnabled] = useState(false);
    const [isVariant, setIsVariant] = useState(false);
    const [variationSelected, setVariationSelected] = useState(null);
    useEffect(() => {
        const loadSuggestions = async () => {
            if (query.length > 2 && query.length % 2 === 0) {
                search(query);
            }
        };
        loadSuggestions();
    }, [query, search]);

    const handleSelect = (selectedOption) => {
        if (scannerEnabled) {
            onSelect(selectedOption);
        } else {
            const product = data.find((prod) => prod.id === selectedOption.value);
            if (product) {
                if (product.isVariant) {
                    setIsVariant(true);
                    setVariationSelected(product);
                    return;
                } else {
                    setIsVariant(false);
                    onSelect(product);
                }
            }
        }
    };

    const handleInputChange = (inputValue) => {
        setQuery(inputValue);
    };

    const handleScanner = async (event) => {
        if (event.key === 'Enter') {
            const toastid = toast.loading('Buscando producto...');
            const barcode = event.target.value;
            // const product = data.find((prod) => prod.code === barcode);
            await get('mainProducts/scanCode', barcode)
                .then((response) => {
                    if (response == "") {
                        toast.error('Producto no encontrado');
                        toast.dismiss(toastid);
                        return;
                    }
                    toast.dismiss(toastid);

                    if(response.isVariant){
                        setIsVariant(true)
                        setVariationSelected(response);
                    }else{
                        setIsVariant(false);
                        onSelect(response, true);
                    }
                });
            // if(product){
            //     setSelectedProduct({value: product.id, label: `${product.name} (${product.code})`});
            //     setQuery(`${product.name} (${product.code})`);
            // }
        }
    }


    const options = data.map((product) => ({
        value: product.id,
        label: `${product?.name} ()`,
    }));

    return (
        <div className="col-lg-12 col-sm-6 col-12">
            <div className="">
                <label>Producto</label>
                <div className="row">

                    <div className="col-lg-10 col-sm-10 col-10">
                        {scannerEnabled ? (<input type="text" className="form-control" onKeyUp={handleScanner} />) : (

                            <Select
                                onChange={handleSelect}
                                options={options}
                                placeholder="Ingresa el nombre o código del producto"
                                onInputChange={handleInputChange}
                                classNamePrefix="react-select"
                                styles={{
                                    input: (provided) => ({
                                        ...provided,
                                        color: 'black', // Ensure input text color is black
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: 'black', // Ensure selected value text color is black
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        color: '#999', // Set placeholder text color
                                    }),
                                }}
                            />
                        )}
                    </div>
                    <div className="col-lg-2 col-sm-2 col-2 ps-0">
                        <div onClick={() => setScannerEnabled(!scannerEnabled)} className="add-icon">
                            <button type="button" className={`btn ${scannerEnabled ? ' btn-primary' : 'btn-light'} me-2`}>
                                <ImageWithBasePath
                                    src="assets/img/icons/scanners.svg"
                                    alt="img"
                                />
                            </button>

                        </div>
                    </div>
                    {scannerEnabled && (<span>Scanner</span>)}
                </div>
            </div>
            <Modal size='lg' centered show={isVariant} onHide={() => setIsVariant(!isVariant)}>
                <div className="content">
                    <div className="modal-header border-0 custom-modal-header">
                        <div className="page-title">
                            <h4>Seleccione variante de: <b>{variationSelected?.name}</b></h4>
                        </div>
                        <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body custom-modal-body">
                        <div className="table-responsive">
                            <table className="table  datanew">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Variación</th>
                                        <th>Precio</th>
                                        <th className="no-sort"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {variationSelected?.products?.map((variant, index) => (
                                        <tr key={index}>
                                            <td>{variant?.barcode}</td>
                                            <td>{variant?.variation?.type?.name} : {variant?.variation?.name}</td>
                                            <td>$ <NumericInput disabled readOnly={true} value={variant.salePrice} /></td>
                                            <td className="action-table-data">
                                                <div className="edit-delete-action">

                                                    <Button className="me-2" onClick={() => {
                                                        onSelect(variant);
                                                        setIsVariant(false);
                                                        // setVariationSelected(null);

                                                    }}>
                                                        Seleccionar
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>
    );
};

ProductSearch.propTypes = {
    data: PropTypes.array.isRequired,
    search: PropTypes.func.isRequired,
    onSelect: propTypes.func,
    scan: propTypes.func,
};

const mapStateToProps = (state) => ({
    data: state.products?.searchData ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    search: (data) => dispatch(searchRequest(data)),
    scan: (code) => dispatch(scanCodeRequest(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);