import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { getRequest, saveRequest, updateRequest, clearEntity } from "../../../redux/units/slice";
import { closeModal } from '../../../redux/modal/slice';
import { connect } from 'react-redux';
import { ModalType } from '../../types';

const UnitForm = ({ save, update, loading, id, isOpen, closeModal, clearEntity, get, entity, isReadOnly, title, type }) => {

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { active: true, ...entity },
        onSubmit: (values) => {
            if (loading) return

            if (id) {
                update({id, ...values});
            } else {
                save(values);
            }
        },
    });

    useEffect(() => {
        if (id) {
            get(id);
        }
    }, [id])

    const handleCancel = () => {
        closeModal();
        clearEntity();
    };

    return (

        <Modal show={ type ===  ModalType.UNIT && isOpen} onHide={handleCancel}>
            <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                        <h4>{title}</h4>
                    </div>
                    <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCancel}
                        value={formik.values.name || ''}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body custom-modal-body">
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input name='name' onChange={formik.handleChange} readOnly={isReadOnly} type="text" className="form-control" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Abreviación</label>
                            <input name='shortName' onChange={formik.handleChange} type="text" className="form-control" />
                        </div>
                        <div className="mb-0">
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                <span className="status-label">Status</span>
                                <input
                                    type="checkbox"
                                    id="user2"
                                    className="check"
                                    defaultChecked="true"
                                />
                                <label htmlFor="user2" className="checktoggle" />
                            </div>
                        </div>
                        <div className="modal-footer-btn">
                            <button
                                type="button"
                                className="btn btn-cancel me-2"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </button>
                            <Link to="#" onClick={formik.handleSubmit} className="btn btn-submit">
                                Guardar
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </Modal >

    )
}


UnitForm.propTypes = {
    handleSave: PropTypes.func,
    loading: PropTypes.bool,
    isOpen: PropTypes.bool,
    id: PropTypes.number,
    closeModal: PropTypes.func,
    get: PropTypes.func,
    entity: PropTypes.object,
    save: PropTypes.func,
    update: PropTypes.func,
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    clearEntity: PropTypes.func,
    type: PropTypes.string,
};



const mapStateToProps = (state) => ({
    isOpen: state.modal.isOpen,
    id: state.modal.id,
    title: state.modal.title,
    type: state.modal.type,
    isReadOnly: state.modal.isReadOnly,
    entity: state.units.entity,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    clearEntity: () => dispatch(clearEntity()),
    get: (id) => dispatch(getRequest(id)),
    save: (data) => dispatch(saveRequest(data)),
    update: (data) => dispatch(updateRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UnitForm);
