import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0,
        items: [],
        },
    loading: false,
    error: null,
    comboData: []
};

const TaxesSlice = createSlice({
    name: 'taxes',
    initialState,
    reducers: {
        saveRequest(state) {
            state.loading = true;
            state.error = null;
        },
        saveSuccess(state, action) {
            state.loading = false;
            state.data.items.push(action.payload);
            state.comboData.push({ label: action.payload?.name, value: action?.payload.id });
            state.entity = {};
        },
        saveFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updateRequest(state) {
            state.loading = true;
            state.error = null;
        },
        updateSuccess(state, action) {
            state.loading = false;
            state.entity = {};
            const index = state.data.items.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.data.items[index] = action.payload;
            }
        },
        updateFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        removeRequest(state) {
            state.loading = true;
            state.error = null;
        },
        removeSuccess(state, action) {
            state.loading = false;
            state.data.items = state.data.items.filter(item => item.id !== action.payload);
            state.entity = {};
        },
        removeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getAllRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getAllSuccess(state, action) {
            state.loading = false;
            state.data = {
                pageNumber: action.payload.pageNumber,
                pageSize: action.payload.pageSize,
                totalCount: action.payload.totalCount,
                items: action.payload.items,
            };
            state.entity = {};
        },
        getAllFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getSuccess(state, action) {
            state.loading = false;
            state.entity =  action.payload;
        },
        getFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getComboRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getComboSuccess(state, action) {
            state.loading = false;
            state.comboData = action.payload;
        },
        getComboFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearEntity(state) {
            state.entity = {};
        }
    }
});

export const {
    saveRequest,
    saveSuccess,
    saveFailure,
    updateRequest,
    updateSuccess,
    updateFailure,
    removeRequest,
    removeSuccess,
    removeFailure,
    getAllRequest,
    getAllSuccess,
    getAllFailure,
    getRequest,
    getSuccess,
    getFailure,
    getComboRequest,
    getComboSuccess,
    getComboFailure,
    clearEntity
} = TaxesSlice.actions;

export default TaxesSlice.reducer;
