import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    error: null,
    comboData: [],
    entity: {},
};

const PeopleSlice = createSlice({
    name: 'people',
    initialState,
    reducers: {
        savePeopleRequest(state) {
            state.loading = true;
            state.error = null;
        },
        savePeopleSuccess(state, action) {
            state.loading = false;
            state.data.push(action.payload);
            state.comboData.push({ label: action.payload?.name, value: action?.payload.id });
        },
        savePeopleFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updatePeopleRequest(state) {
            state.loading = true;
            state.error = null;
        },
        updatePeopleSuccess(state, action) {
            state.loading = false;
            state.entity = {};
            const index = state.data.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.data[index] = action.payload;
            }
        },
        updatePeopleFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        removePeopleRequest(state) {
            state.loading = true;
            state.error = null;
        },
        removePeopleSuccess(state, action) {
        
            console.log(action.payload);
            state.loading = false;
            state.data = state.data.filter(item => item.id !== action.payload)??[];
        },
        removePeopleFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getAllPeoplesRequest(state) {
            console.log("GETALL -------- REQUEST");
            state.loading = true;
            state.error = null;
        },
        getAllPeoplesSuccess(state, action) {
            console.log(action.payload);
            state.loading = false;
            state.data = action.payload;
        },
        getAllPeoplesFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getPeopleRequest(state) {
            console.log("GET -------- REQUEST");
            state.loading = true;
            state.error = null;
        },
        getPeopleSuccess(state, action) {
            state.loading = false;
            state.entity =  action.payload;
        },
        getPeopleFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.entity = {};
        },
        getPeopleComboRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getPeopleComboSuccess(state, action) {
            console.log(action.payload);
            state.loading = false;
            state.comboData = action.payload;
        },
        getPeopleComboFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const {
    savePeopleRequest,
    savePeopleSuccess,
    savePeopleFailure,
    updatePeopleRequest,
    updatePeopleSuccess,
    updatePeopleFailure,
    removePeopleRequest,
    removePeopleSuccess,
    removePeopleFailure,
    getAllPeoplesRequest,
    getAllPeoplesSuccess,
    getAllPeoplesFailure,
    getPeopleRequest,
    getPeopleSuccess,
    getPeopleFailure,
    getPeopleComboRequest,
    getPeopleComboSuccess,
    getPeopleComboFailure
} = PeopleSlice.actions;

export default PeopleSlice.reducer;
