import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    error: null,
    comboData: []
};

const UnitsSlice = createSlice({
    name: 'units',
    initialState,
    reducers: {
        saveRequest(state) {
            state.loading = true;
            state.error = null;
        },
        saveSuccess(state, action) {
            state.loading = false;
            state.data.push(action.payload);
            state.comboData.push({ label: action.payload?.name, value: action?.payload.id });
            state.entity = {};
        },
        saveFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updateRequest(state) {
            state.loading = true;
            state.error = null;
        },
        updateSuccess(state, action) {
            state.loading = false;
            state.entity = {};
            const index = state.data.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.data[index] = action.payload;
            }
        },
        updateFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        removeRequest(state) {
            state.loading = true;
            state.error = null;
        },
        removeSuccess(state, action) {
            state.loading = false;
            state.data = state.data.filter(item => item.id !== action.payload);
            state.entity = {};
        },
        removeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getAllRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getAllSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
            state.entity = {};
        },
        getAllFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getSuccess(state, action) {
            state.loading = false;
            state.entity =  action.payload;
        },
        getFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getComboRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getComboSuccess(state, action) {
            state.loading = false;
            state.comboData = action.payload;
        },
        getComboFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearEntity(state) {
            state.entity = {};
        }
    }
});

export const {
    saveRequest,
    saveSuccess,
    saveFailure,
    updateRequest,
    updateSuccess,
    updateFailure,
    removeRequest,
    removeSuccess,
    removeFailure,
    getAllRequest,
    getAllSuccess,
    getAllFailure,
    getRequest,
    getSuccess,
    getFailure,
    getComboRequest,
    getComboSuccess,
    getComboFailure,
    clearEntity
} = UnitsSlice.actions;

export default UnitsSlice.reducer;
