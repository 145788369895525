import { call, put, takeLatest } from 'redux-saga/effects';
import { loginRequest, loginSuccess, loginFailure, registerRequest, registerSuccess, registerFailure, activateAccountRequest, activateAccountSuccess, activateAccountFailure } from './slice';
import { post } from '../../helpers/apiHelpers';

function* handleLogin(action) {
  const { ...request } = action.payload;
  console.log(request);
  try {
    const user = yield call(post, 'user/authenticate', request.values);
    yield put(loginSuccess(user));
    request.navigate('/admin-dashboard');
  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

function* handleRegister(action) {
  const { ...request } = action.payload;
  try {
    const user = yield call(post, 'user/register', request.values);
    yield put(registerSuccess(user));
    request.navigate('/email-verification', { state: { email: request.values.email } });
  } catch (error) {
    yield put(registerFailure(error.message));
  }
}

function* handleActivateAccount(action) {
  const { token, navigate } = action.payload;
  try {
    const response = yield call(post, 'user/confirmemail', { code:token });
    yield put(activateAccountSuccess(response));
    navigate('/activation-success');
  } catch (error) {
    yield put(activateAccountFailure(error.message));
  }
}

export default function* authSaga() {
  yield takeLatest(loginRequest.type, handleLogin);
  yield takeLatest(registerRequest.type, handleRegister);
  yield takeLatest(activateAccountRequest.type, handleActivateAccount);
}