import React from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Calendar } from "feather-icons-react/build/IconComponents";
import propTypes from 'prop-types';
import { closeModal } from "../../../core/redux/modal/slice";
import { connect } from "react-redux";
import currency from "currency.js";
import { Link } from "react-router-dom";

const ViewModal = ({ isLoading, entity, closeModal }) => {

  console.log(isLoading)
  return (
    <div>
      {/*Add Quotation */}
      <div className="modal fade" id="view-quotation">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Presupuesto: {entity?.reference}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => closeModal()}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks add-product">
                          <label>Customer Name</label>
                          <div className="row">
                            <div className="col-lg-10 col-sm-10 col-10">
                              <input type="text" className="form-control" value={entity?.customer?.name} readOnly />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Fecha</label>
                          <div className="input-groupicon calender-input">
                            <Calendar className="info-img" />
                            <input readOnly value={new Date(entity?.date)} type="text" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-blocks">
                          <label>Referencia</label>
                          <input readOnly value={entity?.reference} type="text" className="form-control" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="table-responsive no-pagination">
                          <table className="table  datanew">
                            <thead>
                              <tr>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Precio U($)</th>
                                <th>Precio Total($)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {entity?.items?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="productimgname">
                                      <Link to="#" className="product-img stock-img">
                                        <ImageWithBasePath src="assets/img/product/noimage.png" alt="product" />
                                      </Link>
                                      <Link to="#">{item?.product?.name ?? '-'}</Link>
                                    </div>
                                  </td>
                                  <td>{item.quantity}</td>
                                  <td>{currency(item.price).format()}</td>
                                  <td>{currency(item.total).format()}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 ms-auto">
                          <div className="total-order w-100 max-widthauto m-auto mb-4">
                            <ul>
                              <li>
                                <h4>Total</h4>
                                <h5>$ {currency(entity.total).toString()}</h5>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks mb-3">
                            <label>Order Tax</label>
                            <input type="text" defaultValue={true} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks mb-3">
                            <label>Discount</label>
                            <input type="text" defaultValue={true} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks mb-3">
                            <label>Shipping</label>
                            <input type="text" defaultValue={true} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks mb-3">
                            <label>Status</label>
                            <Select
                              classNamePrefix="react-select"
                              options={choose}
                              placeholder="Choose"
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-lg-12">
                      <div className="input-blocks summer-description-box">
                        <label>Description</label>
                        <pre>{entity?.description}</pre>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        onClick={() => closeModal()}
                      >
                        Cerrar
                      </button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewModal.propTypes = {
  isLoading: propTypes.bool.isRequired,
  entity: propTypes.object.isRequired,
  closeModal: propTypes.func.isRequired,
};
export default connect(null, { closeModal })(ViewModal);
