import React from "react";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { base_path } from "./environment.jsx";
import "../src/style/css/feather.css";
import "../src/style/css/line-awesome.min.css";
import "../src/style/scss/main.scss";
import "../src/style/icons/fontawesome/css/fontawesome.min.css";
import "../src/style/icons/fontawesome/css/all.min.css";
import { createRoot } from "react-dom/client";

import AllRoutes from "./Router/router.jsx";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./core/redux/store.jsx";
import setupAxios from "./core/redux/setupAxios"; 
import axios from "axios";



const rootElement = document.getElementById("root");

setupAxios(axios, store);

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    // <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={base_path}>
            <AllRoutes />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    // </React.StrictMode>
  );
} else {
  console.error("Element with id 'root' not found.");
}
