import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ProductCarousel.css'; // Create and import a CSS file for styling

const ProductCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="slider-product-details">
            <div className="carousel">
                {images.length > 1 && (
                    <button className="carousel-button prev" onClick={handlePrev}>
                        &#10094;
                    </button>
                )}
                <div className="carousel-images" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {images.map((image, index) => (
                        <div
                            className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
                            key={index}
                        >
                            <img src={image.url} alt={image.alt} />
                        </div>
                    ))}
                </div>
                {images.length > 1 && (
                    <button className="carousel-button next" onClick={handleNext}>
                        &#10095;
                    </button>)}
            </div>
        </div>
    );
};

ProductCarousel.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ProductCarousel;