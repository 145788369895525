import React, { useState } from "react";
import {
  Calendar,
  PlusCircle
} from "feather-icons-react/build/IconComponents";
import { Link } from "react-router-dom";
import { MinusCircle } from "react-feather";
import Select from "react-select";
import { DatePicker } from "antd";
import SelectCustomer from "../components/SelectCustomer";
import CustomerModal from "../../core/modals/peoples/customerModal";
import ProductSearch from "./ProductSearch";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { saveRequest, getRequest, updateRequest } from "../../core/redux/sales/slice"
import PropTypes from "prop-types";

const NewSale = ({ products, save }) => {

  const statusupdate = [
    { value: "Supplier", label: "Seleccione" },
    { value: "Completed", label: "Completada" },
    { value: "InProgress", label: "En Progreso" },
    { value: "InProgress", label: "En Espera" },
    { value: "InProgress", label: "Facturando" },

  ];
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
    formik.setFieldValue("date", date);
  };

  const handleSelectProduct = (selectedOption) => {

    let product;
    product = products.find((prod) => prod.id === selectedOption.mainProductId);
    const item = {
      productId: selectedOption.id,
      name: product.name,
      quantity: 1,
      price: selectedOption.salePrice,
      discount: 0,
      subTotal: selectedOption.salePrice,
      tax: 0,
      total: selectedOption.salePrice,
    };
    formik.setFieldValue("items", [...formik.values.items, item]);
  };


  const formik = useFormik({
    validationSchema: Yup.object({
      // customerId: Yup.string().required("Cliente es requerido"),
      // date: Yup.string().required("Fecha es requerida"),
      // items: Yup.array().required("Productos son requeridos"),
    }),
    initialValues: {
      customerId: "",
      date: "",
      items: [],
      taxes: 0,
      discount: 0,
      shippingCost: 0,
      status: 0,
    },
    onSubmit: (values) => {
      save(values)
    },
  });

  const handleIncrement = (index) => {
    const newQuantity = formik.values.items[index].quantity + 1;
    formik.setFieldValue(`items.${index}.quantity`, newQuantity);
    const unitPrice = formik.values.items[index].price;
    const total = newQuantity * unitPrice;
    formik.setFieldValue(`items.${index}.total`, total);
  };

  const handleDecrement = (index) => {
    if (formik.values.items[index].quantity > 1) {
      const newQuantity = formik.values.items[index].quantity - 1;
      formik.setFieldValue(`items.${index}.quantity`, newQuantity);
      const unitPrice = formik.values.items[index].price;
      const total = newQuantity * unitPrice;
      formik.setFieldValue(`items.${index}.total`, total);
    }
  };
  const handleRemoveItem = (index) => {
    const items = formik.values.items.filter((item, i) => i !== index);
    formik.setFieldValue('items', items);
  }

  return (
    <div className="page-wrapper pagehead">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Crear nueva venta</h4>
          </div>
          <ul className="table-top-head">
            <li>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
              >
                <i data-feather="rotate-ccw" className="feather-rotate-ccw" />
              </a>
            </li>
            <li>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i data-feather="chevron-up" className="feather-chevron-up" />
              </a>
            </li>
          </ul>
        </div>

        <div className="card">
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <SelectCustomer onChange={(v)=>formik.setFieldValue('customerId',v.value)} />
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="input-blocks">
                    <label>Date</label>
                    <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        type="date"
                        className="filterdatepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholder="Choose Date"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-6 col-12">
                  <ProductSearch onSelect={handleSelectProduct} />
                </div>
              </div>
              <div className="table-responsive no-pagination">
                <table className="table  datanew">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>Precio ($)</th>
                      <th>Descuento($)</th>
                      <th>Sub.Total($)</th>
                      <th>Impuesto(%)</th>
                      <th>Total($)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>
                          <div className="product-quantity">
                            <span
                              className="quantity-btn"
                              onClick={() => handleDecrement(index)}
                            >
                              <MinusCircle />
                            </span>
                            <input
                              name={`items.${index}.quantity`}
                              type="text"
                              value={item.quantity}
                              className="quntity-input"
                            />

                            <span
                              className="quantity-btn"
                              onClick={() => handleIncrement(index)}
                            >
                              <PlusCircle className="plus-circle" />
                            </span>
                          </div>
                        </td>
                        <td>{item.price}</td>
                        <td>{item.discount}</td>
                        <td>{item.subTotal}</td>
                        <td>{item.tax}</td>
                        <td>{item.total}</td>
                        <td>
                          <Link
                            className="confirm-text p-2"
                            to="#"
                            onClick={() => handleRemoveItem(index)}
                          >
                            <i data-feather="trash-2" className="feather-trash-2"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-lg-6 ms-auto">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      <li>
                        <h4>Impuestos</h4>
                        <h5>$ 0.00</h5>
                      </li>
                      <li>
                        <h4>Descuentos</h4>
                        <h5>$ 0.00</h5>
                      </li>
                      <li>
                        <h4>Costo envio</h4>
                        <h5>$ 0.00</h5>
                      </li>
                      <li>
                        <h4>Sub Total</h4>
                        <h5>$ 0.00</h5>
                      </li>
                      <li>
                        <h4>Total</h4>
                        <h5>$ 0.00</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks">
                    <label>Impuestos</label>
                    <div className="input-groupicon select-code">
                      <input
                        type="text"
                        defaultValue={0}
                        className="p-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks">
                    <label>Descuentos</label>
                    <div className="input-groupicon select-code">
                      <input
                        type="text"
                        defaultValue={0}
                        className="p-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks">
                    <label>Costo Envío</label>
                    <div className="input-groupicon select-code">
                      <input
                        type="text"
                        defaultValue={0}
                        className="p-2"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks mb-5">
                    <label>Estado</label>
                    <Select
                      classNamePrefix="react-select"
                      options={statusupdate}
                      placeholder="status"
                    />
                  </div>
                </div>
                <div className="col-lg-12 text-end">
                  <button
                    type="button"
                    className="btn btn-cancel add-cancel me-3"
                    data-bs-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <Button onClick={formik.handleSubmit} className="btn btn-success add-sale">
                    Crear venta
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomerModal />
    </div>
  );
};


const mapStateToProps = (state) => ({
  products: state.products?.searchData ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  save: (id) => dispatch(saveRequest(id)),
  get: (id) => dispatch(getRequest(id)),
  update: (data) => dispatch(updateRequest(data))

});

NewSale.propTypes = {
  save: PropTypes.func,
  addClient: PropTypes.func,
  getCustomers: PropTypes.func,
  customers: PropTypes.array,
  products: PropTypes.array,
  entity: PropTypes.object,
  get: PropTypes.func,
  update: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSale);
