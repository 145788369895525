import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { getRequest, saveRequest, updateRequest, clearEntity } from "../../../redux/brand/slice";
import { closeModal } from '../../../redux/modal/slice';
import { connect } from 'react-redux';
import { ModalType } from '../../types';
import * as Yup from "yup";

const BrandForm = ({ save, update, loading, id, isOpen, closeModal, clearEntity, get, entity, isReadOnly, title, type }) => {
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null); // State for logo preview

    const formik = useFormik({
        validationSchema: Yup.object().shape({
            name: Yup.string().required("El nombre es requerido"),
        }),
        enableReinitialize: true,
        initialValues: {
            name: '',
            active: true, 
            ...entity
        },
        onSubmit: (values) => {
            if (loading) return

            const formData = new FormData();
            formData.append('logo', logo);
            formData.append('name', values.name);
            formData.append('active', values.active);

            if (id) {
                formData.append('id', id);
                update(formData);
            } else {
                save(formData);
            }
            setLogo(null);
            setLogoPreview(null); // Clear the preview after submission

        },
    });

    useEffect(() => {
        if (id) {
            get(id);
        }
    }, [id])

    const handleCancel = () => {
        closeModal();
        clearEntity();
        setLogo(null);
        setLogoPreview(null)
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setLogo(file);
        setLogoPreview(URL.createObjectURL(file)); // Set the preview URL
    };
    return (

        <Modal show={type === ModalType.BRAND && isOpen} onHide={handleCancel}>
            <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                        <h4>{title}</h4>
                    </div>
                    <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCancel}
                        value={formik.values.name || ''}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body custom-modal-body">
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Marca</label>
                            <input value={formik.values.name} name='name' onChange={formik.handleChange} type="text" className="form-control" />
                            {formik.errors.name && formik.touched.name && (<p className="text-danger">{formik.errors.name}</p>)}
                        </div>
                        <label className="form-label">Logo</label>
                        <div className="profile-pic-upload mb-3">
                            <div className="profile-pic brand-pic">
                                {
                                    logoPreview ? <img src={logoPreview} alt="brand" /> : <img src={formik.values.logoUrl} alt="brand" />
                                }
                            </div>
                            <div className="image-upload mb-0">
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={handleFileChange}
                                    accept="image/*"
                                />
                                <div className="image-uploads">
                                    <h4>Cambiar Imagen</h4>
                                </div>
                            </div>
                        </div>
                        <div className="mb-0">
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                <span className="status-label">Estado</span>
                                <input
                                    name='active'
                                    checked={formik.values.active}
                                    readOnly={isReadOnly}
                                    type="checkbox"
                                    id="active"
                                    className="check"
                                    defaultChecked={'true'}
                                />
                                <label onClick={() => formik.setFieldValue('active', !formik.values.active)} htmlFor="active" className="checktoggle" />
                            </div>
                        </div>
                        <div className="modal-footer-btn">
                            <button
                                type="button"
                                className="btn btn-cancel me-2"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </button>
                            <Link to="#" onClick={formik.handleSubmit} className="btn btn-submit">
                                Guardar
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>

    )
}


BrandForm.propTypes = {
    handleSave: PropTypes.func,
    loading: PropTypes.bool,
    isOpen: PropTypes.bool,
    id: PropTypes.number,
    closeModal: PropTypes.func,
    get: PropTypes.func,
    entity: PropTypes.object,
    save: PropTypes.func,
    update: PropTypes.func,
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    clearEntity: PropTypes.func,
    type: PropTypes.string,
};



const mapStateToProps = (state) => ({
    isOpen: state.modal.isOpen,
    id: state.modal.id,
    title: state.modal.title,
    tittypele: state.modal.type,
    isReadOnly: state.modal.isReadOnly,
    entity: state.brand.entity,
    type: state.modal.type,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    clearEntity: () => dispatch(clearEntity()),
    get: (id) => dispatch(getRequest(id)),
    save: (data) => dispatch(saveRequest(data)),
    update: (data) => dispatch(updateRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
