import React, { useEffect } from 'react'
import ImageWithBasePath from '../../../core/img/imagewithbasebath'
import PropTypes from 'prop-types'
import { getRequest, removeRequest } from '../../../core/redux/mainproducts/slice'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import Barcode from 'react-barcode'
import QRCode from 'react-qr-code'
import currency from 'currency.js'
import ProductImagesCarousel from './ProductImagesCarousel'
const ProductDetail = ({ entity, get }) => {
    const params = useParams();
    useEffect(() => {
        get(params?.id)
    }, [])
    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="page-title">
                            <h4>Detalle de producto</h4>
                            <h6>Información completa del producto</h6>
                        </div>
                    </div>
                    {/* /add */}
                    <div className="row">
                        <div className="col-lg-8 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="bar-code-view">
                                        <Barcode height={40} value={entity?.barcode} />
                                        {/* <ImageWithBasePath src="assets/img/barcode/barcode1.png" alt="barcode" /> */}
                                        <a className="printimg">
                                            <ImageWithBasePath src="assets/img/icons/printer.svg" alt="print" />
                                        </a>
                                    </div>
                                    <div className="productdetails">
                                        <ul className="product-bar">
                                            <li>
                                                <h4>Producto</h4>
                                                <h6>{entity?.name}</h6>
                                            </li>
                                            <li>
                                                <h4>SKU</h4>
                                                <h6>{entity?.sku}</h6>
                                            </li>
                                            <li>
                                                <h4>Categoría</h4>
                                                <h6>{entity?.category?.label}</h6>
                                            </li>
                                            <li>
                                                <h4>Marca</h4>
                                                <h6>{entity?.brand?.label}</h6>
                                            </li>
                                            <li>
                                                <h4>Unidad</h4>
                                                <h6>{entity?.unit?.label}</h6>
                                            </li>

                                            <li>
                                                <h4>Estado</h4>
                                                <h6>{entity.active ? "Activo" : "Inactivo"}</h6>
                                            </li>
                                            <li>
                                                <h4>Descripción</h4>
                                                <h6>
                                                    {entity?.description}
                                                </h6>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                   <ProductImagesCarousel images={entity?.images??[]} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /add */}

                    <div className="col-lg-12">
                        <div className="modal-body-table">
                            <div className="table-responsive">
                                <table className="table  datanew">
                                    <thead>
                                        <tr>
                                            {/* <th>Product</th> */}
                                            <th>SKU</th>
                                            {entity?.isVariant && <th>Variante</th>}
                                            <th>Stock</th>
                                            <th>Alerta Stock Bajo</th>
                                            <th>Precio venta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entity?.products?.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="productimgname">
                                                        <Link
                                                            to="#"
                                                            className="product-img stock-img"
                                                        >
                                                            <div style={{ height: "auto", margin: "0 auto", maxWidth: 40, width: "100%" }}>
                                                                <QRCode
                                                                    size={256}
                                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                    value={product?.sku}
                                                                    viewBox={`0 0 256 256`}
                                                                />
                                                                {'  ' }{product?.sku}
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </td>
                                                {entity?.isVariant &&<td> {product.variation?.type?.name}: {product.variation?.name}</td>}
                                                <td>{product?.stock?.quantity || '-'}</td>
                                                <td>{product?.stock?.alertQuantity || '-'}</td>
                                                <td>
                                                    {currency(product?.salePrice, { symbol: '$', precision: 2 }).format()}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

const mapStateToProps = (state) => ({
    entity: state.products?.entity ?? {}
});

const mapDispatchToProps = (dispatch) => ({
    remove: (id) => dispatch(removeRequest(id)),
    get: (id) => dispatch(getRequest(id)),

});

ProductDetail.propTypes = {
    entity: PropTypes.object,
    remove: PropTypes.func,
    get: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);