import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  ChevronUp,
  Download,
  Eye,
  FileText,
  PlusCircle,
  // Printer,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import { setToogleHeader } from "../../../core/redux/action";
import { connect, useDispatch, useSelector } from "react-redux";
import { Box, Filter, Sliders, StopCircle, User } from "react-feather";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../../core/pagination/datatable";
import { all_routes } from "../../../Router/all_routes";
import { getAllRequest, removeRequest, getRequest } from "../../../core/redux/quotations/slice";
import PropTypes from "prop-types";
import currency from "currency.js";
import ViewModal from "./ViewModal";
const QuotationList = ({ dataSource, entity, getAll, remove, get }) => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.toggle_header);
  const [vieModalLoading, setViewModalLoading] = useState(false);
  // const dataSource = useSelector((state) => state.mock.quotationlist_data);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };
  const oldandlatestvalue = [
    { value: "date", label: "Sort by Date" },
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];
  const products = [
    { value: "Choose product", label: "Choose product" },
    { value: "Bold V3.2", label: "Bold V3.2" },
    { value: "Apple Series 5 Watch", label: "Apple Series 5 Watch" },
  ];
  const status = [
    { value: "Choose product", label: "Choose Status" },
    { value: "Ordered", label: "Ordered" },
    { value: "Sent", label: "Sent" },
  ];
  const customername = [
    { value: "Choose Custmer", label: "Choose Customer" },
    { value: "walk-in-customer", label: "walk-in-customer" },
    { value: "John Smith", label: "John Smith" },
  ];

  const renderTooltip = (props) => (
    <Tooltip id="pdf-tooltip" {...props}>
      Pdf
    </Tooltip>
  );
  const renderExcelTooltip = (props) => (
    <Tooltip id="excel-tooltip" {...props}>
      Excel
    </Tooltip>
  );
  const renderPrinterTooltip = (props) => (
    <Tooltip id="printer-tooltip" {...props}>
      Printer
    </Tooltip>
  );
  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const columns = [
    {
      title: "Referencia",
      dataIndex: "reference",
      sorter: (a, b) => a.reference.length - b.reference.length,
    },
    {
      title: "Cliente",
      dataIndex: "customer",
      sorter: (a, b) => a.customer?.label?.length - b.customer?.label?.length,
      render: (text) => (text?.label)
    },
    {
      title: "Fecha",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Estado",
      dataIndex: "status",
      render: (status) => (
        <div>
          {status === 0 && (
            <span className="badge bg-light text-dark">Borrador</span>
          )}
          {status === 1 && (
            <span className="badge bg-success">Enviado</span>
          )}
          {status === 2 && (
            <span className="badge bg-success">Aceptado</span>
          )}
          {status === 3 && (
            <span className="badge bg-warning">Rechazado</span>
          )}
          {status === 4 && (
            <span className="badge bg-secondary">Creado</span>
          )}
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Total ($)",
      dataIndex: "total",
      sorter: (a, b) => a.total.length - b.total.length,
      render: (text) => `${currency(text).format()}`
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (id,row) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
          <Link
              className="me-2 p-2"
              onClick={() => handleOpenView(id)}>
              Crear venta
            </Link>
            <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#view-quotation"
              onClick={() => handleOpenView(id)}>
              <Eye className="feather-view" />
            </Link>
            <Link  className="me-2 p-2" to={row.url} target="popup">
              <Download className="feather-view" />
            </Link>
            <Link
              className="me-2 p-2"
              to={`/edit-quotation/${id}`}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(id)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        remove(id);
      } else {
        MySwal.close();
      }
    });
  };

  useEffect(() => {
    getAll()
  }, [])

  const handleOpenView = (id) => {
    setViewModalLoading(true);
    get(id);
  }
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Listado de presupuesto</h4>
                <h6>Administre sus presupuestos</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <Link>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderExcelTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderPrinterTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="page-btn">
              <Link
                to={all_routes.newQuotation}
                className="btn btn-added"
              >
                <PlusCircle className="me-2" />
                Nuevo Presupuesto
              </Link>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Buscar"
                      className="form-control form-control-sm formsearch"
                    />
                    <Link to className="btn btn-searchset">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
                <div className="search-path">
                  <div className="d-flex align-items-center">
                    <Link
                      className={`btn btn-filter ${isFilterVisible ? "setclose" : ""
                        }`}
                      id="filter_search"
                    >
                      <Filter
                        className="filter-icon"
                        onClick={toggleFilterVisibility}
                      />
                      <span onClick={toggleFilterVisibility}>
                        <ImageWithBasePath
                          src="assets/img/icons/closes.svg"
                          alt="img"
                        />
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="form-sort">
                  <Sliders className="info-img" />
                  <Select
                    className="img-select"
                    classNamePrefix="react-select"
                    options={oldandlatestvalue}
                    placeholder="Los más nuevos"
                  />
                </div>
              </div>
              {/* /Filter */}
              <div
                className={`card${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              >
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Box className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={products}
                          placeholder="Choose Product"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <StopCircle className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={status}
                          placeholder="Choose Status"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <User className="info-img" />
                        <Select
                          className="img-select"
                          classNamePrefix="react-select"
                          options={customername}
                          placeholder="Choose Custmer"
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <FileText className="info-img" />
                        <div className="input-groupicon">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Reference"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-12">
                      <div className="input-blocks">
                        <Link className="btn btn-filters ms-auto">
                          {" "}
                          <i
                            data-feather="search"
                            className="feather-search"
                          />{" "}
                          Search{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Filter */}
              <div className="table-responsive">
                <Table columns={columns} dataSource={dataSource?.items ?? []}
                  pagination={{
                    defaultPageSize: 10,
                    total: dataSource.totalCount,
                    showSizeChanger: true, pageSizeOptions: ['10', '20', '30'],
                    onChange: (pageNumber, pageSize) => {
                      getAll({ pageNumber, pageSize });
                      // fetchRecords(page, pageSize);
                    },
                  }} />
              </div>
            </div>
          </div>
          {/* /product list */}
          <ViewModal isLoading={vieModalLoading} entity={entity} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  dataSource: state.quotations?.data ?? [],
  entity: state.quotations?.entity ?? {},
});

const mapDispatchToProps = (dispatch) => ({
  getAll: (filter) => dispatch(getAllRequest(filter)),
  remove: (id) => dispatch(removeRequest(id)),
  get: (id) => dispatch(getRequest(id))

});

QuotationList.propTypes = {
  dataSource: PropTypes.array.isRequired,
  remove: PropTypes.func,
  getAll: PropTypes.func,
  get: PropTypes.func,
  entity: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationList);
