import { call, put, takeLatest } from 'redux-saga/effects';
import {
    saveSupplierRequest,
    saveSupplierSuccess,
    saveSupplierFailure,
    updateSupplierRequest,
    updateSupplierSuccess,
    updateSupplierFailure,
    removeSupplierRequest,
    removeSupplierSuccess,
    removeSupplierFailure,
    getAllSuppliersRequest,
    getAllSuppliersSuccess,
    getAllSuppliersFailure,
    getSupplierRequest,
    getSupplierSuccess,
    getSupplierFailure,
    getSupplierComboRequest,
    getSupplierComboSuccess,
    getSupplierComboFailure
} from './slice';
import { closeModal } from '../modal/slice';
import toast from 'react-hot-toast';
import { get, post, update, remove, getAll } from '../../helpers/apiHelpers';

function* saveSupplierSaga(action) {
    try {
        const response = yield call(post, 'Peoples', action.payload);
        yield put(saveSupplierSuccess(response));
        yield put(closeModal());
        toast.success('Registro guardado correctamente.');
    } catch (error) {
        yield put(saveSupplierFailure(error.message));
        toast.error('Ocurrió un error al guardar el registro.');
    }
}

function* updateSupplierSaga(action) {
    try {
        const response = yield call(update, 'Peoples', action.payload);
        yield put(updateSupplierSuccess(response));
        yield put(closeModal());
        toast.success('Registro actualizado correctamente.');
    } catch (error) {
        yield put(updateSupplierFailure(error.message));
        toast.error('Ocurrió un error al actualizar el registro.');
    }
}

function* removeSupplierSaga(action) {
    try {
        yield call(remove, 'Peoples', action.payload);
        yield put(removeSupplierSuccess(action.payload));
        toast.success('Registro eliminado correctamente.');
    } catch (error) {
        yield put(removeSupplierFailure(error.message));
        toast.error('Ocurrió un error al eliminar el registro.');
    }
}

function* getAllSuppliersSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(getAll, 'Peoples', payload);
        yield put(getAllSuppliersSuccess(response));
    } catch (error) {
        yield put(getAllSuppliersFailure(error.message));
    }
}

function* getSupplierSaga(action) {
    try {
        const response = yield call(get, 'Peoples', action.payload);
        yield put(getSupplierSuccess(response));
    } catch (error) {
        yield put(getSupplierFailure(error.message));
    }
}

function* getSupplierComboSaga() {
    try {
        const response = yield call(get, 'Peoples');
        yield put(getSupplierComboSuccess(response));
    } catch (error) {
        yield put(getSupplierComboFailure(error.message));
    }
}

export default function* SupplierSaga() {
    yield takeLatest(saveSupplierRequest.type, saveSupplierSaga);
    yield takeLatest(updateSupplierRequest.type, updateSupplierSaga);
    yield takeLatest(removeSupplierRequest.type, removeSupplierSaga);
    yield takeLatest(getAllSuppliersRequest.type, getAllSuppliersSaga);
    yield takeLatest(getSupplierRequest.type, getSupplierSaga);
    yield takeLatest(getSupplierComboRequest.type, getSupplierComboSaga);
}
