import React from "react";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
function NumericInput({ onChange, onBlur, value, name, readOnly, ...props }) {
  return <NumericFormat
    readOnly={readOnly}
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    fixedDecimalScale={true}
    onValueChange={onChange}
    onBlur={onBlur}
    value={value}
    name={name}
    {...props} />;
}
NumericInput.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
};
export default NumericInput;
