import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    error: null,
    comboData: []
};

const MainProductsSlice = createSlice({
    name: 'mainproducts',
    initialState,
    reducers: {
        saveRequest(state) {
            state.loading = true;
            state.error = null;
        },
        saveSuccess(state, action) {
            state.loading = false;
            state.data.items.push(action.payload);
            state.entity = {};
        },
        saveFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updateRequest(state) {
            state.loading = true;
            state.error = null;
        },
        updateSuccess(state, action) {
            state.loading = false;
            state.entity = {};
            const index = state.data?.items.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.data.items[index] = action.payload;
            }
        },
        updateFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        removeRequest(state) {
            state.loading = true;
            state.error = null;
        },
        removeSuccess(state, action) {
            state.loading = false;
            state.data.items = state.data.items.filter(item => item.id !== action.payload);
            state.entity = {};
        },
        removeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getAllRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getAllSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
            state.entity = {};
        },
        getAllFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getSuccess(state, action) {
            state.loading = false;
            state.entity =  action.payload;
        },
        getFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getComboRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getComboSuccess(state, action) {
            state.loading = false;
            state.comboData = action.payload;
        },
        getComboFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        searchRequest(state) {
            state.loading = true;
            state.error = null;
        },
        searchSuccess(state, action) {
            state.loading = false;
            state.searchData = action.payload;
        },
        searchFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },

        scanCodeRequest(state) {
            state.loading = true;
            state.error = null;
        },
        scanCodeSuccess(state, action) {
            state.loading = false;
            state.searchData = action.payload;
        },
        scanCodeFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },

        clearEntity(state) {
            state.entity = {};
        }
    }
});

export const {
    saveRequest,
    saveSuccess,
    saveFailure,
    updateRequest,
    updateSuccess,
    updateFailure,
    removeRequest,
    removeSuccess,
    removeFailure,
    getAllRequest,
    getAllSuccess,
    getAllFailure,
    getRequest,
    getSuccess,
    getFailure,
    getComboRequest,
    getComboSuccess,
    getComboFailure,
    searchRequest,
    searchSuccess,
    searchFailure,
    clearEntity,
    scanCodeRequest,
    scanCodeSuccess,
    scanCodeFailure
} = MainProductsSlice.actions;

export default MainProductsSlice.reducer;
