import React, { useEffect, useState } from "react";

import { DatePicker } from "antd";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { Calendar, PlusCircle } from "feather-icons-react/build/IconComponents";
import { MinusCircle } from "react-feather";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { saveRequest, getRequest, updateRequest } from "../../../core/redux/quotations/slice"
import { getPeopleComboRequest } from "../../../core/redux/people/slice";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { openModal } from "../../../core/redux/modal/slice";
import { ModalType } from "../../../core/modals/types";
import CustomerModal from "../../../core/modals/peoples/customerModal";
import ProductSearch from "../ProductSearch";
import toast from "react-hot-toast";
import currency from "currency.js";
import { all_routes } from "../../../Router/all_routes";
import * as Yup from 'yup';
import { Alert } from "react-bootstrap";
import NumericInput  from "../../components/NumericInput";
import SelectCustomer from "../../components/SelectCustomer";
const Form = ({ entity, get, save, update, products }) => {
  const [generatePDF, setGeneratePDF] = useState(true);
  const [customer, setCustomer] = useState("Cliente");
  const params = useParams();
  const status = [
    { value: 0, label: "Borrador" },
    { value: 1, label: "Enviado" },
    { value: 2, label: "Aceptado" },
    { value: 3, label: "Rechazado" },
    { value: 4, label: "Created" },
  ];


  const handleIncrement = (index) => {
    const newQuantity = formik.values.items[index].quantity + 1;
    formik.setFieldValue(`items.${index}.quantity`, newQuantity);
    const unitPrice = formik.values.items[index].price;
    const total = newQuantity * unitPrice;
    formik.setFieldValue(`items.${index}.total`, total);
  };

  const handleDecrement = (index) => {
    if (formik.values.items[index].quantity > 1) {
      const newQuantity = formik.values.items[index].quantity - 1;
      formik.setFieldValue(`items.${index}.quantity`, newQuantity);
      const unitPrice = formik.values.items[index].price;
      const total = newQuantity * unitPrice;
      formik.setFieldValue(`items.${index}.total`, total);
    }
  };

  const handleRecalculate = (index) => {
    const unitPrice = formik.values.items[index].price;
    const total = formik.values.items[index].quantity * unitPrice;
    formik.setFieldValue(`items.${index}.total`, total);
  }

  const handleCustomerChange = (selectedOption) => {
    formik.setFieldValue('customerId', selectedOption.value)
    setCustomer(selectedOption.label);
    // formik.setFieldValue('customer', selectedOption)
    formik.setFieldValue('reference', `PRE-${selectedOption.label.toUpperCase()}-${new Date().getFullYear()}`.replace(/\s/g, ''));
  }

  const handleRemoveItem = (index) => {
    const items = formik.values.items.filter((item, i) => i !== index);
    formik.setFieldValue('items', items);
  }
  const validationSchema = Yup.object().shape({
    items: Yup.array()
      .required('Presupuesto debe tener al menos un producto')
      .min(1, 'Presupuesto debe tener al menos un producto'),
    customerId: Yup.string().required('Cliente es requerido'),
  });

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      customerId: "",
      // date: new Date(),
      reference: "",
      total: 0,
      taxTotal: 0,
      discountTotal: 0,
      shippingCost: 0,
      // status: 0,
      description: "",
      items: [],
      generatePdf: generatePDF,
      ...entity


    },
    onSubmit: (values) => {
      if (params.id) {
        update(values);
      } else {
        save(values);
      }
    },
  });


  useEffect(() => {
    if (params.id) {
      get(params.id);
    }
  }, []);

  const handleSelect = (selectedOption, isScanner) => {
    const productId = selectedOption.id;
    let product = null;
    if (isScanner) {
      product = selectedOption;
    } else {
      product = products.find((prod) => prod.id === productId);
    }

    if (formik.values.items.find(item => item.productId === productId)) {
      const index = formik.values.items.findIndex(item => item.productId === productId);
      const newQuantity = formik.values.items[index].quantity + 1;
      const price = formik.values.items[index].price;
      const total = newQuantity * price;
      formik.setFieldValue(`items.${index}.quantity`, newQuantity);
      formik.setFieldValue(`items.${index}.total`, total);
    } else {
      if (product.isVariant) {
        toast.error('Aún no se pueden agregar productos variantes');
        return;
      }

      formik.setFieldValue('items', [...formik.values.items, {
        name: product.name,
        productId: product.id,
        quantity: 1,
        discount: 0,
        tax: 0,
        taxValue: 0,
        price: product.products[0].salePrice,
        total: product.products[0].salePrice
      }]);
    }
    toast.success('Producto agregado correctamente');
  }

  //#region Calculate total
  useEffect(() => {
    const total = formik.values.items.reduce((acc, item) => acc + item.total, 0) + formik.values.taxTotal + formik.values.shippingCost - formik.values.discountTotal;
    formik.setFieldValue('total', total);
    console.log(formik.values.items)
  }, [formik.values]);
  console.log(formik.values)
  const RenderTitle = () => {
    if (params.id) {
      return <>
        <h4>Editar Presupuesto</h4>
        <h6>Editar presupuesto existente</h6>
      </>
    } else {
      return <>
        <h4>Nuevo Presupuesto</h4>
        <h6>Generar nuevo presupuesto</h6>
      </>
    }
  }
  return (
    <div className="page-wrapper pagehead">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            {RenderTitle()}
          </div>
          <ul className="table-top-head">
            <li>
              <div className="status-toggle d-flex justify-content-between align-items-center">
                <input
                  type="checkbox"
                  id="pdf"
                  className="check"
                  defaultChecked={generatePDF}
                />
                <label onClick={() => setGeneratePDF(!generatePDF)} htmlFor="pdf" className="checktoggle">
                  Generar PDF
                </label>
                Generar PDF
              </div>
            </li>
            <li>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
              >
                <i data-feather="rotate-ccw" className="feather-rotate-ccw" />
              </a>
            </li>
            <li>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Refresh"
              >
                <ImageWithBasePath
                  src="assets/img/icons/pdf.svg"
                  alt="img"
                />
              </a>
            </li>
            <li>
              <a
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Collapse"
                id="collapse-header"
              >
                <i data-feather="chevron-up" className="feather-chevron-up" />
              </a>
            </li>
          </ul>
        </div>

        <div className="card">
          <div className="card-body">
            <form>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-12">
                  <SelectCustomer value={entity?.customerId} errors={formik.touched.customerId && formik.errors.customerId } onChange={handleCustomerChange} />
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="input-blocks">
                    <label>Vencimiento</label>
                    <div className="input-groupicon calender-input">
                      <Calendar className="info-img" />
                      <DatePicker
                        name="validTo"
                        showNow={false}
                        selected={formik.values.validTo}
                        onChange={(js, date) => {
                          formik.setFieldValue('validTo', date)
                          formik.setFieldValue('reference', `PRE-${customer.toUpperCase()}_${js.get('day')}_${js.get('month') + 1}`.replace(/\s/g, ''));
                        }}
                        type="date"
                        className="filterdatepicker"
                        dateFormat="dd-MM-yyyy"
                        placeholder="Fecha vencimiento"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-12">
                  <div className="input-blocks">
                    <label>Referencia</label>
                    <input defaultValue={'PRE-'} value={formik.values.reference ?? ''} onChange={formik.handleChange} name="reference" type="text" />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-6 col-12">
                  <ProductSearch onSelect={handleSelect} />
                </div>
              </div>
              <div className="table-responsive no-pagination">
                {formik.values.items.length > 0 ? (
                  <table className="table  datanew">
                    <thead>
                      <tr>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        {/* <th>Descuento($)</th> */}
                        {/* <th>Impuesto(%)</th> */}
                        {/* <th>Valor Impuesto($)</th> */}
                        <th>Precio U($)</th>
                        <th>Precio Total($)</th>
                        <th>  </th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.items.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="productimgname">
                              <Link to="#" className="product-img stock-img">
                                <ImageWithBasePath src="assets/img/product/noimage.png" alt="product" />
                              </Link>
                              <Link to="#">{item.name}</Link>
                            </div>
                          </td>
                          <td>
                            <div className="product-quantity">
                              <span
                                className="quantity-btn"
                                onClick={() => handleDecrement(index)}
                              >
                                <MinusCircle />
                              </span>
                              <input
                                name={`items.${index}.quantity`}
                                type="text"
                                value={item.quantity}
                                className="quntity-input"
                              />

                              <span
                                className="quantity-btn"
                                onClick={() => handleIncrement(index)}
                              >
                                <PlusCircle className="plus-circle" />
                              </span>
                            </div>
                          </td>
                          {/* <td>{item.discount}</td> */}
                         <td> <NumericInput onBlur={()=>handleRecalculate(index )} className="form-control w-10" onValueChange={(value)=>formik.setFieldValue(`items.${index}.price`,value.floatValue)}  value={item.price} /> </td>
                         <td>{<NumericInput className="form-control w-10" readOnly={true} value={item.total} /> }</td>
                          <td> <Link
                            className="confirm-text p-2"
                            to="#"
                            onClick={() => handleRemoveItem(index)}
                          >
                            <i data-feather="trash-2" className="feather-trash-2"></i>
                          </Link></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>) : <Alert className="mt-2" variant="info">Aún no has agregado ningún producto al presupuesto</Alert>}
                {formik.errors.items && formik.touched.items && <Alert className="mt-4" variant="danger">{formik.errors.items}</Alert>}
              </div>
              <div className="row">
                <div className="col-lg-6 ms-auto">
                  <div className="total-order w-100 max-widthauto m-auto mb-4">
                    <ul>
                      {/* <li>
                        <h4>Impuestos</h4>
                        <h5>$ 0.00</h5>
                      </li> */}
                      {/* <li>
                        <h4>Descuentos</h4>
                        <h5>$ 0.00</h5>
                      </li>
                      <li>
                        <h4>Envío</h4>
                        <h5>{currency(formik.values.shippingCost).toString()}</h5>
                      </li> */}
                      <li>
                        <h4>Total</h4>
                        <h5> {currency(formik.values.total).format()}</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks mb-3">
                    <label>Impuestos</label>
                    <div className="input-groupicon select-code">
                      <input type="text" placeholder={0} />
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks mb-3">
                    <label>Descuentos</label>
                    <div className="input-groupicon select-code">
                      <input type="text" placeholder={0} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks mb-3">
                    <label>Envíos</label>
                    <div className="input-groupicon select-code">
                      <input type="text" placeholder={0} />
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-3 col-sm-6 col-12">
                  <div className="input-blocks mb-3">
                    <label>Estado</label>
                    <Select
                      name="status"
                      onChange={(e) => formik.setFieldValue('status', e.value)}
                      classNamePrefix="react-select"
                      options={status}
                      placeholder="Estado"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-blocks summer-description-box">
                    <label>Description</label>
                    <textarea name="description" onChange={formik.handleChange}>{formik.values.description}</textarea>
                  </div>
                </div>
                <div className="col-lg-12 text-end">
                  <Link
                    to={all_routes.quotationlist}
                    type="button"
                    className="btn btn-cancel add-cancel me-3"
                  >
                    Cancelar
                  </Link>
                  <Link onClick={formik.handleSubmit} className="btn btn-submit add-sale">
                    Guardar
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CustomerModal />
    </div>
  );
};


const mapStateToProps = (state) => ({
  customers: state.people?.comboData ?? [],
  products: state.products?.searchData ?? [],
  entity: state.quotations?.entity ?? {},
});

const mapDispatchToProps = (dispatch) => ({
  save: (id) => dispatch(saveRequest(id)),
  addClient: () => dispatch(openModal({ type: ModalType.CUSTOMER, title: 'Agregar Cliente' })),
  getCustomers: () => dispatch(getPeopleComboRequest({ type: 'customer' })),
  get: (id) => dispatch(getRequest(id)),
  update: (data) => dispatch(updateRequest(data))

});

Form.propTypes = {
  save: PropTypes.func,
  addClient: PropTypes.func,
  getCustomers: PropTypes.func,
  customers: PropTypes.array,
  products: PropTypes.array,
  entity: PropTypes.object,
  get: PropTypes.func,
  update: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
