import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Select from "react-select";
import { all_routes } from "../../../Router/all_routes";
import { DatePicker, Row } from "antd";
import {
  ArrowLeft,
  Calendar,
  Check,
  ChevronDown,
  ChevronUp,
  Image,
  Info,
  LifeBuoy,
  List,
  PlusCircle,
  // Trash2,
  X,
} from "feather-icons-react/build/IconComponents";
import { connect, useDispatch, useSelector } from "react-redux";
import { setToogleHeader } from "../../../core/redux/action";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getRequest, saveRequest, updateRequest } from "../../../core/redux/mainproducts/slice";
import { useFormik } from "formik";
import { getComboRequest as getBrandsCombo } from "../../../core/redux/brand/slice";
import { getComboRequest as getCategoriesCombo } from "../../../core/redux/category/slice";
import { getComboRequest as getUnitsCombo } from "../../../core/redux/units/slice";
import { getAllRequest as getAllVariations } from "../../../core/redux/variationtypes/slice";
import UnitForm from "../../../core/modals/inventory/Units/UnitForm";
import { openModal } from "../../../core/redux/modal/slice";
import { ModalType } from "../../../core/modals/types";
import BrandForm from "../../../core/modals/inventory/Brand/brandForm";
import { getAllRequest as GetAllTaxesRequest } from '../../../core/redux/taxes/slice';
import CategoryForm from "../../../core/modals/inventory/categories/categoryForm";
import Products from "./Products";
import { useParams } from "react-router-dom";
import * as Yup from 'yup';

const AddForm = ({ save, update, getBrandsCombo, getCategoriesCombo, getUnitsCombo, categories, units, brands, getAllVariations, variations, getAllTaxes, taxes, getProduct, entity }) => {
  const route = all_routes;
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [autogenerateBarcode, setAutogenerateBarcode] = useState(false);

  const params = useParams();

  const data = useSelector((state) => state.toggle_header);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState(0); // 0 for single, 1 for multiple
  const [selectedVariationType, setSelectedVariationType] = useState([]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );


  const discounttype1 = [
    { value: "choose", label: "Choose" },
    { value: "percentage", label: "Percentage" },
    { value: "cash", label: "Cash" },
  ];


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nombre del producto es requerido'),
    categoryId: Yup.string().required('Categoria es requerida'),
    brandId: Yup.string().required('Marca es requerida'),
    unitId: Yup.string().required('Unidad es requerida'),
  });

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name: '',
      sku: '',
      categoryId: '',
      brandId: '',
      unitId: '',
      barcode: '',
      type: 0,
      products: [],
      files: [],
      ...entity,


    },
    onSubmit: (values) => {
      // if (loading) return;

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'files') {
          images.forEach((fileWrapper) => {
            formData.append('files', fileWrapper.file); // Append each file with the same field name
          });
        } else if (key === 'products') {
          values.products.forEach((product, index) => {
            if (values.type === 1) { // if variant add variantId
              formData.append(`products[${index}][variationId]`, product.variationId);
            }
            formData.append(`products[${index}][quantity]`, product.quantity);
            formData.append(`products[${index}][purchasePrice]`, product.purchasePrice);
            formData.append(`products[${index}][salePrice]`, product.salePrice);
            formData.append(`products[${index}][taxType]`, product.taxType);
            formData.append(`products[${index}][discountType]`, product.discountType);
            formData.append(`products[${index}][discountValue]`, product.discountValue);
            formData.append(`products[${index}][profitMargin]`, product.profitMargin);
            formData.append(`products[${index}][quantityAlert]`, product.quantityAlert);
            if (product.taxId) {
              formData.append(`products[${index}][taxId]`, product.taxId);
            }
          });
        } else {
          formData.append(key, values[key]);
        }
      });

      if (params.id > 0) {
        formData.append('id', params.id)
      }

      //  files.forEach((file,index) => formik.setFieldValue(`Files[${index}]`, file ));
      if (params.id) {
        update(formData)
      } else {
        save(formData);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  })

  useEffect(() => {
    getBrandsCombo();
    getCategoriesCombo();
    getUnitsCombo();
    getAllVariations()
    getAllTaxes();
    setSelectedType(0)
  }, [])

  useEffect(() => {
    getProduct(params.id);
  }, [params.id]);

  const handleOpenUnitsModal = () => {
    dispatch(openModal({ type: ModalType.UNIT, title: 'Agregar unidad' }))
  }

  const handleOpenBrandModal = () => {
    dispatch(openModal({ type: ModalType.BRAND, title: 'Agregar marca' }))
  }

  const handleOpenCategoryModal = () => {
    dispatch(openModal({ type: ModalType.CATEGORY, title: 'Agregar categoria' }))
  }

  const handleSelectedVariationType = (e) => {
    const { value } = e;
    const variants = variations.find(v => v.id === value)?.variations;

    setSelectedVariationType(variants.map(x => ({ value: x.id, label: x.name })));
  }


  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setImages((prevImages) => [...prevImages, ...newImages]);

  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages];
      URL.revokeObjectURL(updatedImages[index].preview); // Revoke the object URL to free up memory
      updatedImages.splice(index, 1);
      return updatedImages;
    });
    formik.setFieldValue('files', formik.values.files.filter((_, i) => i !== index));
  };




  const handleSwitchType = (type) => {
    setSelectedType(type)
    formik.setFieldValue('products', [])
    formik.setFieldValue('type', type)
  }
  useEffect(() => {
    if (params.id) {
      setSelectedType(entity.type)
    }

  }, [entity]);

  const renderTooltip = (props) => (
    <Tooltip id="tooltip-primary" className='tooltip-primary' {...props}>
      Si el producto no tiene Código de Barras, haga clic en Auto-Generar código para generar un Código de Barras automáticamente.
    </Tooltip>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Nuevo producto</h4>
              <h6>Crear nuevo producto</h6>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={route.productlist} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />
                  Volver a productos
                </Link>
              </div>
            </li>
            <li>
              <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                <Link
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Collapse"
                  id="collapse-header"
                  className={data ? "active" : ""}
                  onClick={() => {
                    dispatch(setToogleHeader(!data));
                  }}
                >
                  <ChevronUp className="feather-chevron-up" />
                </Link>
              </OverlayTrigger>
            </li>
          </ul>
        </div>
        {/* /add */}
        <form>
          <div className="card">
            <div className="card-body add-product pb-0">
              <div
                className="accordion-card-one accordion"
                id="accordionExample"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      <div className="addproduct-icon">
                        <h5>
                          <Info className="add-info" />

                          <span>Información del producto</span>
                        </h5>
                        <Link to="#">
                          <ChevronDown className="chevron-down-add" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {/* <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Store</label>
                            <Select
                              classNamePrefix="react-select"
                              options={store}
                              placeholder="Choose"
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">Warehouse</label>
                            <Select
                              classNamePrefix="react-select"
                              options={warehouse}
                              placeholder="Choose"
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label"><span>*</span> Nombre del Producto</label>
                            <input value={formik.values?.name || ''} name="name" onChange={formik.handleChange} type="text" className="form-control" />
                            {formik.touched.name && formik.errors.name ? (
                              <div className="text-danger mt-2">{formik.errors.name}</div>
                            ) : null}
                          </div>
                        </div>

                      </div>
                      <div className="addservice-info">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label"><span>* </span>Categoría</label>
                                <Link
                                  to="#"
                                  onClick={handleOpenCategoryModal}
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Agregar</span>
                                </Link>
                              </div>
                              <Select
                                classNamePrefix="react-select"
                                options={categories}
                                placeholder="Seleccione"
                                name="categoryId"
                                value={formik.values.category}
                                onChange={(e) => formik.setFieldValue('categoryId', e.value)}
                              />
                              {formik.touched.categoryId && formik.errors.categoryId ? (
                                <div className="text-danger mt-2">{formik.errors.categoryId}</div>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Sub Category</label>
                              <Select
                                classNamePrefix="react-select"
                                options={subcategory}
                                placeholder="Seleccione"
                              />
                            </div>
                          </div> */}
                          {/* <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">
                                Sub Sub Category
                              </label>
                              <Select
                                classNamePrefix="react-select"
                                options={subsubcategories}
                                placeholder="Seleccione"
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="add-product-new">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label"><span>* </span>Marca</label>
                                <Link
                                  to="#"
                                  onClick={handleOpenBrandModal}
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Agregar</span>
                                </Link>
                              </div>
                              <Select
                                value={formik.values.brand}
                                classNamePrefix="react-select"
                                options={brands}
                                placeholder="Seleccione"
                                onChange={(e) => formik.setFieldValue('brandId', e.value)}
                              />
                              {formik.touched.brandId && formik.errors.brandId ? (
                                <div className="text-danger mt-2">{formik.errors.brandId}</div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <div className="add-newplus">
                                <label className="form-label"><span>* </span>Unit</label>
                                <Link
                                  to="#"
                                  onClick={handleOpenUnitsModal}
                                >
                                  <PlusCircle className="plus-down-add" />
                                  <span>Agregar</span>
                                </Link>
                              </div>
                              <Select
                                value={formik.values.unit}
                                classNamePrefix="react-select"
                                options={units}
                                placeholder="Seleccione"
                                onChange={(e) => formik.setFieldValue('unitId', e.value)}
                              />
                              {formik.touched.unitId && formik.errors.unitId ? (
                                <div className="text-danger mt-2">{formik.errors.unitId}</div>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="col-lg-4 col-sm-6 col-12">
                            <div className="mb-3 add-product">
                              <label className="form-label">Selling Type</label>
                              <Select
                                classNamePrefix="react-select"
                                options={sellingtype}
                                placeholder="Seleccione"
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-6 col-sm-6 col-12">
                          <div className="mb-3 add-product">
                            <label className="form-label">
                              Típo de código de barras
                            </label>
                            <Select
                              classNamePrefix="react-select"
                              options={barcodesymbol}
                              placeholder="Seleccione"
                            />
                          </div>
                        </div> */}
                        <div className="col-lg-6 col-sm-6 col-12">
                          <div className="input-blocks add-product list">
                            <label>Código de Barras  <span>* </span></label>
                            <input
                              onChange={formik.handleChange}
                              name="barcode"
                              type="text"
                              className="form-control list"
                              placeholder={autogenerateBarcode ? 'Auto-generado' : 'Ingrese el código de barras'}
                              disabled={autogenerateBarcode}
                            />
                            <Link
                              className="btn btn-primaryadd"
                              onClick={() => setAutogenerateBarcode(!autogenerateBarcode)}
                            >
                              <OverlayTrigger placement="top"
                                overlay={renderTooltip} offset={[0, 15]} >
                                <span className="d-inline-block">
                                  Auto-Generar código
                                </span>
                              </OverlayTrigger>
                            </Link>
                            {formik.touched.barcode && formik.errors.barcode ? (
                              <div className="text-danger mt-2">{formik.errors.barcode}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* Editor */}
                      <div className="col-lg-12">
                        <div className="input-blocks summer-description-box transfer mb-3">
                          <label>Descripción</label>
                          <textarea
                            name="Description"
                            className="form-control h-100"
                            rows={5}
                            defaultValue={""}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                          />
                          <p className="mt-1">Máximo 500 caracteres</p>
                        </div>
                      </div>
                      {/* /Editor */}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-card-one accordion"
                id="accordionExample2"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list icon">
                          <h5>
                            <LifeBuoy className="add-info" />
                            <span>Precio &amp; Disponibilidad</span>
                          </h5>
                          <Link to="#">
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show  mb-5"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="input-blocks add-products">
                        <label className="d-block">Tipo de producto</label>
                        <div className="single-pill-product">
                          <ul
                            className="nav nav-pills"
                            id="pills-tab1"
                            role="tablist"
                          >
                            <li className="nav-item" role="presentation">
                              <span
                                className={`custom_radio me-4 mb-0 ${selectedType === 0 ? 'active' : ''}`}
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected={selectedType === 0}
                                onClick={() => handleSwitchType(0)}
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="type"
                                  checked={selectedType === 0}
                                  onChange={() => handleSwitchType(0)}
                                />
                                <span className="checkmark" /> Único
                              </span>
                            </li>
                            <li className="nav-item" role="presentation">
                              <span
                                className={`custom_radio me-2 mb-0 ${selectedType === 1 ? 'active' : ''}`}
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected={selectedType === 1}
                                onClick={() => handleSwitchType(1)}
                              >
                                <input
                                  type="radio"
                                  className="form-control"
                                  name="type"
                                  checked={selectedType === 1}
                                  onChange={() => handleSwitchType(1)}
                                />
                                <span className="checkmark" /> Variable
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >

                          <Row>
                            {selectedType === 0 && <Products isVariant={false} formik={formik} taxes={taxes} readOnly={params?.id} />}
                          </Row>

                        </div>
                        <div
                          className={`tab-pane fade ${selectedType === 1 ? 'show active' : ''}`}
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div className="row mb-2">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <Select
                                classNamePrefix="react-select"
                                options={variations?.map((v) => ({ value: v.id, label: v.name })) ?? []}
                                placeholder="Seleccionar tipo de variación"
                                onChange={handleSelectedVariationType}
                              />
                            </div>

                          </div>
                          {
                            selectedVariationType.length > 0 && selectedType === 1 && (
                              <Products isVariant={true} formik={formik} variations={selectedVariationType} taxes={taxes} readOnly={params?.id} />
                            )
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="accordion-card-one accordion"
                id="accordionExample3"
              >
                <div className="accordion-item">
                  <div
                    className="accordion-header"
                    id="headingThree"
                  >
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-controls="collapseThree"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list">
                          <h5>
                            <Image className="add-info" />
                            <span>Imagenes</span>
                          </h5>
                          <Link to="#">
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample3"
                  >
                    <div className="accordion-body">
                      <div className="text-editor add-list add">
                        <div className="col-lg-12">
                          <div className="add-choosen">
                            <div className="input-blocks">
                              <div className="image-upload">
                                <input name="files" type="file" multiple onChange={handleImageUpload} />
                                <div className="image-uploads">
                                  <PlusCircle className="plus-down-add me-0" />
                                  <h4>Aregar Imagenes</h4>
                                </div>
                              </div>
                            </div>
                            {images.map((image, index) => (
                              <div className="phone-img" key={index}>
                                <img src={image.preview} alt={`image-${index}`} />
                                <Link to="#">
                                  <X
                                    className="x-square-add remove-product"
                                    onClick={() => handleRemoveImage(index)}
                                  />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div hidden
                className="accordion-card-one accordion"
                id="accordionExample4"
              >
                <div className="accordion-item">
                  <div className="accordion-header" id="headingFour">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-controls="collapseFour"
                    >
                      <div className="text-editor add-list">
                        <div className="addproduct-icon list">
                          <h5>
                            <List className="add-info" />
                            <span>Custom Fields</span>
                          </h5>
                          <Link to="#">
                            <ChevronDown className="chevron-down-add" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample4"
                  >
                    <div className="accordion-body">
                      <div className="text-editor add-list add">
                        <div className="custom-filed">
                          <div className="input-block add-lists">
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Warranties
                            </label>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Manufacturer
                            </label>
                            <label className="checkboxs">
                              <input type="checkbox" />
                              <span className="checkmarks" />
                              Expiry
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks add-product">
                              <label>Discount Type</label>
                              <Select
                                classNamePrefix="react-select"
                                options={discounttype1}
                                placeholder="Choose"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks add-product">
                              <label>Quantity Alert</label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Manufactured Date</label>
                              <div className="input-groupicon calender-input">
                                <Calendar className="info-img" />
                                <DatePicker
                                  selected={selectedDate}
                                  onChange={handleDateChange}
                                  type="date"
                                  className="datetimepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div className="input-blocks">
                              <label>Expiry On</label>
                              <div className="input-groupicon calender-input">
                                <Calendar className="info-img" />
                                <DatePicker
                                  selected={selectedDate1}
                                  onChange={handleDateChange1}
                                  type="date"
                                  className="datetimepicker"
                                  dateFormat="dd-MM-yyyy"
                                  placeholder="Choose Date"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-addproduct mb-4">
              <Link to={route.productlist} className="btn btn-secondary">
                <ArrowLeft className="me-2" />
                Cancelar
              </Link>
              {' '}
              <Link onClick={formik.handleSubmit} className="btn btn-success">
                <Check className="me-2" />
                Guardar
              </Link>
            </div>
          </div>
        </form>
        {/* /add */}
      </div >
      <UnitForm />
      <BrandForm />
      <CategoryForm />
    </div >
  );
};



const mapStateToProps = (state) => ({
  entity: state.products?.entity ?? [],
  loading: state.products.loading,
  categories: state.category?.comboData ?? [],
  units: state.units?.comboData ?? [],
  brands: state.brand?.comboData ?? [],
  variations: state.variations?.data ?? [],
  taxes: state.taxes?.data?.items ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  save: (data) => dispatch(saveRequest(data)),
  update: (data) => dispatch(updateRequest(data)),
  getBrandsCombo: () => dispatch(getBrandsCombo()),
  getCategoriesCombo: () => dispatch(getCategoriesCombo()),
  getUnitsCombo: () => dispatch(getUnitsCombo()),
  getAllVariations: () => dispatch(getAllVariations()),
  getAllTaxes: () => dispatch(GetAllTaxesRequest()),
  getProduct: (id) => dispatch(getRequest(id)),
});

AddForm.propTypes = {
  loading: PropTypes.bool,
  save: PropTypes.func,
  update: PropTypes.func,
  getBrandsCombo: PropTypes.func,
  getCategoriesCombo: PropTypes.func,
  getUnitsCombo: PropTypes.func,
  categories: PropTypes.array,
  units: PropTypes.array,
  brands: PropTypes.array,
  variations: PropTypes.array,
  getAllVariations: PropTypes.func,
  getAllTaxes: PropTypes.func,
  taxes: PropTypes.array,
  getProduct: PropTypes.func,
  entity: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddForm);