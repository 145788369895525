// features.js
const featureFlags = {
    dashboard: {
      adminDashboard: false,
      salesDashboard: false,
    },
    products: {
      productList: false,
      addProduct: false,
      editProduct: false,
      productDetails: false,
    },
    brands: {
      brandList: false,
    },
    units: {
      units: false,
    },
    attributes: {
      variantAttributes: false,
    },
    warranty: {
      warranty: false,
    },
    barcode: {
      barcode: false,
    },
    uiComponents: {
      alerts: false,
      accordion: false,
      avatar: false,
      badges: false,
      borders: false,
      buttons: false,
      buttonsGroup: false,
      breadcrumb: false,
      cards: false,
      dropdowns: false,
      colors: false,
      carousel: false,
      navTabs: false,
      grid: false,
      images: false,
      lightbox: false,
      media: false,
      modals: false,
      offcanvas: false,
      pagination: false,
      typography: false,
      tooltip: false,
      dragAndDrop: false,
      clipboard: false,
      popover: false,
      toasts: false,
      video: false,
      sweetAlerts: false,
      spinner: false,
      progress: false,
      placeholder: false,
      rating: false,
      textEditor: false,
      counter: false,
      scrollbar: false,
      stickyNote: false,
      timeline: false,
      rangeSlider: false,
    },
    charts: {
      apexChart: false,
      chartJs: false,
    },
    icons: {
      fontAwesome: false,
      featherIcon: false,
      ionicIcons: false,
      materialIcons: false,
      pe7Icons: false,
      simpleLine: false,
      themifyIcons: false,
      iconWeather: false,
      typicons: false,
      flagIcons: false,
    },
    communication: {
      chat: false,
      videoCall: false,
      audioCall: false,
      email: false,
      callHistory: false,
      todo: false,
    },
    forms: {
      basicInput: false,
      checkboxRadio: false,
      inputGroup: false,
      gridGutters: false,
      formSelect: false,
      fileUpload: false,
      formMask: false,
      formHorizontal: false,
      formVertical: false,
      floatingLabel: false,
      formValidation: false,
      select2: false,
      wizard: false,
    },
    inventory: {
      expiredProduct: false,
      lowStock: false,
      categoryList: false,
      subCategories: false,
      purchaseList: false,
      purchaseOrderReport: false,
      purchaseReturn: false,
      manageStock: false,
      stockAdjustment: false,
      stockTransfer: false,
    },
    expenses: {
      expenseList: false,
      expenseCategory: false,
    },
    calendar: {
      calendar: false,
    },
    settings: {
      appearance: false,
      socialAuthentication: false,
      languageSettings: false,
      invoiceSettings: false,
      printerSettings: false,
      posSettings: false,
      customFields: false,
      emailSettings: false,
      smsSettings: false,
      otpSettings: false,
      gdprSettings: false,
      paymentGateway: false,
      bankSettingsList: false,
      bankSettingsGrid: false,
      taxRates: false,
      currencySettings: false,
      storageSettings: false,
      banIpAddress: false,
      generalSettings: false,
      securitySettings: false,
      notification: false,
      connectedApps: false,
      systemSettings: false,
      companySettings: false,
      localizationSettings: false,
      prefixes: false,
      preference: false,
    },
    reports: {
      salesReport: false,
      purchaseReport: false,
      inventoryReport: false,
      supplierReport: false,
      customerReport: false,
      expenseReport: false,
      incomeReport: false,
      taxReport: false,
      profitLoss: false,
    },
    employees: {
      attendanceAdmin: false,
      attendanceEmployee: false,
      employeeGrid: false,
      addEmployee: false,
      editEmployee: false,
      leavesAdmin: false,
      leavesEmployee: false,
      leavesType: false,
      payrollList: false,
    },
    warehouses: {
      warehouses: false,
    },
    coupons: {
      coupons: false,
    },
    customers: {
      customers: false,
    },
    suppliers: {
      suppliers: false,
    },
    stores: {
      storeList: false,
    },
    notes: {
      notes: false,
    },
    fileManager: {
      fileManager: false,
    },
    profile: {
      profile: false,
    },
    authentication: {
      signin: false,
      signinTwo: false,
      signinThree: false,
      register: false,
      registerTwo: false,
      registerThree: false,
      forgotPassword: false,
      forgotPasswordTwo: false,
      forgotPasswordThree: false,
      resetPassword: false,
      resetPasswordTwo: false,
      resetPasswordThree: false,
      emailVerification: false,
      emailVerificationTwo: false,
      emailVerificationConfirm: false,
      twoStepVerification: false,
      twoStepVerificationTwo: false,
      twoStepVerificationThree: false,
      lockScreen: false,
    },
    errors: {
      error404: false,
      error500: false,
    },
    pages: {
      blankPage: false,
      comingSoon: false,
      underMaintenance: false,
    },
    users: {
      users: false,
      rolesPermission: false,
      permissions: false,
      deleteAccount: false,
    },
  };
  
  export default featureFlags;