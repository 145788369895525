import axios from "axios"
import qs from 'qs';
export async function get(url,id, config = {}) {
  if(id === undefined)
    return await axios.get(process.env.REACT_APP_API_URL + url, { ...config }).then(response => response.data)
  else
  return await axios.get(process.env.REACT_APP_API_URL + url+ '/' + id, { ...config }).then(response => response.data)
}

export async function getAll(url, filter, config = {}) {
  console.log(filter)
  return await axios.get(process.env.REACT_APP_API_URL + url, {
    ...config,
    params: filter,
    paramsSerializer: params => qs.stringify(params)
  }).then(response => response.data);
}


export async function post(url, data, config = {}) {
  const headers = data instanceof FormData || Object.hasOwn(data) ? { 'Content-Type': 'multipart/form-data', 'accept': '*/*' } : {};
  return axios
    .post(process.env.REACT_APP_API_URL + url,  data , { ...config, headers })
    .then(response => response.data)
}

export async function update(url, data, config = {}) {
  let id;
  if(data instanceof FormData)
    id = data.get('id')
  else
    id = data.id

  return axios
    .put(process.env.REACT_APP_API_URL + url +'/'+id, data , { ...config })
    .then(response => response.data)
}

export async function remove(url,id, config = {}) {
  return await axios
    .delete(process.env.REACT_APP_API_URL + url + '/' + id, { ...config })
    .then(response => response.data)
}

export async function download(url, config = {}, type = 'blob') {
  return await axios.get( process.env.REACT_APP_API_URL + url,{
    responseType: type,
    ...config
  })
  .then(response => response.data)
}
