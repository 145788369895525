import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from 'yup';
import { Modal } from "react-bootstrap";
import { getRequest, saveRequest, updateRequest, clearEntity } from "../../../redux/taxes/slice";
import { closeModal } from '../../../redux/modal/slice';
import { ModalType } from '../../types';
const TaxRateForm = ({ save, update, loading, id, isOpen, closeModal, clearEntity, get, entity, isReadOnly, title, type }) => {

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Nombre es requerido'),
        rate: Yup.number().required('Valor es requerido')
            .min(0, 'Valor no puede ser menor a 0')
            .max(100, 'Valor no puede ser mayor a 100'),
        active: Yup.boolean(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { active: true, ...entity },
        validationSchema,
        onSubmit: (values) => {
            if (loading) return

            if (id) {
                update({ id, ...values });
            } else {
                save(values);
            }
        },
    });

    useEffect(() => {
        if (id) {
            get(id);
        }
    }, [id])

    const handleCancel = () => {
        closeModal();
        clearEntity();
    };
    return (
        <Modal show={type === ModalType.TAX && isOpen} centered onHide={handleCancel}>
            <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                        <h4>{title}</h4>
                    </div>
                    <div className="status-toggle modal-status d-flex justify-content-between align-items-center ms-auto me-2">
                        <input
                            name='active'
                            checked={formik.values.active}
                            readOnly={isReadOnly}
                            type="checkbox"
                            id="active"
                            className="check"
                            defaultChecked={'true'}
                        />
                        <label onClick={() => formik.setFieldValue('active', !formik.values.active)} htmlFor="active" className="checktoggle">
                            {" "}
                        </label>
                    </div>
                    <button
                        type="button"
                        className="close"
                        onClick={handleCancel}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body custom-modal-body">
                    <form>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label className="form-label">
                                        Name <span> *</span>
                                    </label>
                                    <input
                                        name='name'
                                        value={formik.values.name || ''}
                                        onChange={formik.handleChange}
                                        readOnly={isReadOnly}
                                        type="text"
                                        className="form-control"
                                        defaultValue="IVA"
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="text-danger">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="mb-0">
                                    <label className="form-label">
                                        Valor % <span> *</span>
                                    </label>
                                    <input
                                        name='rate'
                                        value={formik.values.rate || ''}
                                        onChange={formik.handleChange}
                                        type="text"
                                        className="form-control"
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.rate && formik.errors.rate ? (
                                        <div className="text-danger">{formik.errors.rate}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer-btn">
                            <button
                                type="button"
                                className="btn btn-cancel me-2"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </button>
                            <Link to="#" onClick={formik.handleSubmit} className="btn btn-submit">
                                Guardar
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </Modal >


    )
}

TaxRateForm.propTypes = {
    handleSave: PropTypes.func,
    loading: PropTypes.bool,
    isOpen: PropTypes.bool,
    id: PropTypes.number,
    closeModal: PropTypes.func,
    get: PropTypes.func,
    entity: PropTypes.object,
    save: PropTypes.func,
    update: PropTypes.func,
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    clearEntity: PropTypes.func,
    type: PropTypes.string,
};



const mapStateToProps = (state) => ({
    isOpen: state.modal.isOpen,
    id: state.modal.id,
    title: state.modal.title,
    type: state.modal.type,
    isReadOnly: state.modal.isReadOnly,
    entity: state.taxes.entity,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    clearEntity: () => dispatch(clearEntity()),
    get: (id) => dispatch(getRequest(id)),
    save: (data) => dispatch(saveRequest(data)),
    update: (data) => dispatch(updateRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TaxRateForm);
