import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { getRequest, saveRequest, updateRequest, clearEntity } from "../../../redux/category/slice";
import { closeModal } from '../../../redux/modal/slice';
import { connect } from 'react-redux';
import { ModalType } from '../../types';
import * as Yup from "yup";

const CategoryForm = ({ save, update, loading, id, isOpen, closeModal, clearEntity, get, entity, isReadOnly, title, type }) => {
    const formik = useFormik({
        validationSchema: Yup.object().shape({
            name: Yup.string().required("El nombre es requerido"),
        }),
        enableReinitialize: true,
        initialValues: {
            name: '',
            active: true,
            ...entity
        },
        onSubmit: (values) => {
            if (loading) return

            if (id) {
                update({ ...values, id });
            } else {
                save(values);
            }

        },
    });

    useEffect(() => {
        if (id) {
            get(id);
        }
    }, [id])

    const handleCancel = () => {
        closeModal();
        clearEntity();
    };

    return (
        <Modal show={type === ModalType.CATEGORY && isOpen} onHide={handleCancel}>
            <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                        <h4>{title}</h4>
                    </div>
                    <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCancel}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body custom-modal-body">
                    <form>
                        <div className="mb-3">
                            <label className="form-label">Categoría</label>
                            <input
                                name='name'
                                value={formik.values.name}
                                readOnly={isReadOnly}
                                onChange={formik.handleChange}
                                type="text"
                                className="form-control" />
                            {formik.errors.name && formik.touched.name && (<p className="text-danger">{formik.errors.name}</p>)}
                        </div>

                        <div className="mb-0">
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                <span className="status-label">Activa</span>
                                <input
                                    name='active'
                                    checked={formik.values.active}
                                    readOnly={isReadOnly}
                                    type="checkbox"
                                    id="active"
                                    className="check"
                                    defaultChecked={'true'}
                                />
                                <label onClick={() => formik.setFieldValue('active', !formik.values.active)} htmlFor="active" className="checktoggle" />
                            </div>
                        </div>
                        <div className="modal-footer-btn">
                            <button
                                type="button"
                                className="btn btn-cancel me-2"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </button>
                            <Link to="#" onClick={formik.handleSubmit} className="btn btn-submit">
                                Guardar
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}


CategoryForm.propTypes = {
    handleSave: PropTypes.func,
    loading: PropTypes.bool,
    isOpen: PropTypes.bool,
    id: PropTypes.number,
    closeModal: PropTypes.func,
    get: PropTypes.func,
    entity: PropTypes.object,
    save: PropTypes.func,
    update: PropTypes.func,
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    clearEntity: PropTypes.func,
    type: PropTypes.string,
};



const mapStateToProps = (state) => ({
    isOpen: state.modal.isOpen,
    id: state.modal.id,
    title: state.modal.title,
    isReadOnly: state.modal.isReadOnly,
    entity: state.category.entity,
    type: state.modal.type,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    clearEntity: () => dispatch(clearEntity()),
    get: (id) => dispatch(getRequest(id)),
    save: (data) => dispatch(saveRequest(data)),
    update: (data) => dispatch(updateRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
