import { call, put, takeLatest } from 'redux-saga/effects';
import {
    savePeopleRequest,
    savePeopleSuccess,
    savePeopleFailure,
    updatePeopleRequest,
    updatePeopleSuccess,
    updatePeopleFailure,
    removePeopleRequest,
    removePeopleSuccess,
    removePeopleFailure,
    getAllPeoplesRequest,
    getAllPeoplesSuccess,
    getAllPeoplesFailure,
    getPeopleRequest,
    getPeopleSuccess,
    getPeopleFailure,
    getPeopleComboRequest,
    getPeopleComboSuccess,
    getPeopleComboFailure
} from './slice';
import { closeModal } from '../modal/slice';
import toast from 'react-hot-toast';
import { get, post, update, remove } from '../../helpers/apiHelpers';

function* savePeopleSaga(action) {
    try {
        const response = yield call(post, 'Peoples', action.payload);
        yield put(savePeopleSuccess(response));
        yield put(closeModal());
        toast.success('Registro guardado');
    } catch (error) {
        yield put(savePeopleFailure(error.message));
        toast.error('Error al guardar el registro');
    }
}

function* updatePeopleSaga(action) {
    try {
        const response = yield call(update, 'Peoples', action.payload);
        yield put(updatePeopleSuccess(response));
        yield put(closeModal());
        toast.success('Registro actualizado');
    } catch (error) {
        yield put(updatePeopleFailure(error.message));
        toast.error('Error al actualizar el registro');
    }
}

function* removePeopleSaga(action) {
    try {
        yield call(remove, 'Peoples', action.payload);
        yield put(removePeopleSuccess(action.payload));
    } catch (error) {
        yield put(removePeopleFailure(error.message));
    }
}

function* getAllPeoplesSaga(action) {
    try {
        const { payload } = action;
        const response = yield call(get, 'Peoples', payload);
        console.log("GETALL",response);
        yield put(getAllPeoplesSuccess(response));
    } catch (error) {
        yield put(getAllPeoplesFailure(error.message));
    }
}

function* getPeopleSaga(action) {
    try {
        const response = yield call(get, 'Peoples', action.payload);
        console.log("GET",response);
        yield put(getPeopleSuccess(response));
    } catch (error) {
        yield put(getPeopleFailure(error.message));
    }
}

function* getPeopleComboSaga() {
    try {
        const response = yield call(get, 'Peoples/combo');
        yield put(getPeopleComboSuccess(response));
    } catch (error) {
        yield put(getPeopleComboFailure(error.message));
    }
}

export default function* PeopleSaga() {
    yield takeLatest(savePeopleRequest.type, savePeopleSaga);
    yield takeLatest(updatePeopleRequest.type, updatePeopleSaga);
    yield takeLatest(removePeopleRequest.type, removePeopleSaga);
    yield takeLatest(getAllPeoplesRequest.type, getAllPeoplesSaga);
    yield takeLatest(getPeopleRequest.type, getPeopleSaga);
    yield takeLatest(getPeopleComboRequest.type, getPeopleComboSaga);
}
