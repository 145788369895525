import React, { useEffect, useState } from "react";
import Select from "react-select";
import { closeModal } from '../../redux/modal/slice'; // Adjust the import path as needed
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { getSupplierRequest, saveSupplierRequest, updateSupplierRequest } from "../../redux/supplier/slice";

const SupplierModal = ({ save, update, loading, id, isOpen, closeModal, get, entity, isReadOnly, title }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const countriesOptions = [
    { value: "Seleccionar", label: "Seleccionar" },
    { value: 1, label: "Argentina" },
    { value: 2, label: "Brasil" },
    { value: 3, label: "Bolivia" },
    { value: 4, label: "Paraguay" },
    { value: 5, label: "Uruguay" },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...entity },
    onSubmit: (values) => {
      if (loading) return
      const formData = new FormData();
      formData.append('type', 1); // Supplier
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('address', values.address);
      formData.append('city', values.city);
      formData.append('phone', values.phone);
      formData.append('countryId', values.countryId);
      formData.append('description', values.description);
      if (selectedFile) {
        formData.append('image', selectedFile);
      }
      if (id) {
        formData.append('id', id);
        update(formData);
      } else {
        save(formData);
      }
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (id) {
      get(id);
    }
  }, [id])

  const handleCancel = () => {
    closeModal();
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  return (
    <>
      {/* Add Supplier */}
      <Modal show={isOpen} onHide={handleCancel}>
        <div className="content">
          <div className="modal-header border-0 custom-modal-header">
            <div className="page-title">
              <h4>{title}</h4>
            </div>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCancel}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body custom-modal-body">
            <form>
              <div className="modal-title-head people-cust-avatar">
                <h6>Imagen</h6>
              </div>
              <div className="new-employee-field">
                <div className="profile-pic-upload">
                  <div className="profile-pic">
                    {formik.values.imageUrl ? <img src={formik.values.imageUrl} alt="Profile Preview" className="img-thumbnail" />
                      : imagePreview ? (
                        <img src={imagePreview} alt="Profile Preview" className="img-thumbnail" />
                      ) : (
                        <span>
                          <i
                            data-feather="plus-circle"
                            className="plus-down-add"
                          />{" "}
                          Agregar imagen
                        </span>
                      )}
                  </div>
                  {!isReadOnly ?? <div className="mb-3">
                    <div className="image-upload mb-0">
                      <input type="file" onChange={handleImageChange} />
                      <div className="image-uploads">
                        <h4>Change Image</h4>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 pe-0">
                  <div className="mb-3">
                    <label className="form-label">Nombre</label>
                    <input
                      readOnly={isReadOnly}
                      name="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-lg-4 pe-0">
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input
                      readOnly={isReadOnly}
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      type="email" className="form-control" />
                  </div>
                </div>
                <div className="col-lg-4 pe-0">
                  <div className="input-blocks">
                    <label className="mb-2">Teléfono</label>
                    <input
                      readOnly={isReadOnly}
                      className="form-control form-control-lg group_formcontrol"
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-lg-12 pe-0">
                  <div className="mb-3">
                    <label className="form-label">Dirección</label>
                    <input
                      readOnly={isReadOnly}
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-lg-6 pe-0">
                  <div className="mb-3">
                    <label className="form-label">Ciudad</label>
                    <input
                      readOnly={isReadOnly}
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      type="text" className="form-control" />
                  </div>
                </div>
                <div className="col-lg-6 pe-0">
                  <div className="mb-3">
                    <label className="form-label">Pais</label>
                    <Select
                      classNamePrefix="react-select"
                      options={countriesOptions}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="mb-3 input-blocks">
                    <label className="form-label">Descripcion</label>
                    <textarea
                      readOnly={isReadOnly}
                      name="description"
                      onChange={formik.handleChange}
                      className="form-control mb-1"
                      defaultValue={formik.values.description}
                    />
                    <p>Máximo 60 caracteres.</p>
                  </div>
                </div>
              </div>
              {!isReadOnly &&
                <div className="modal-footer-btn">
                  <button
                    type="button"
                    className="btn btn-cancel me-2"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </button>
                  <button type="button" onClick={formik.handleSubmit} className="btn btn-submit">
                    Guardar
                  </button>
                </div>
              }
            </form>
          </div>
        </div>
      </Modal>
      {/* /Add supplier */}
    </>
  );
};

SupplierModal.propTypes = {
  handleSave: PropTypes.func,
  loading: PropTypes.bool,
  isOpen: PropTypes.bool,
  id: PropTypes.number,
  closeModal: PropTypes.func,
  get: PropTypes.func,
  entity: PropTypes.object,
  save: PropTypes.func,
  update: PropTypes.func,
  isReadOnly: PropTypes.bool,
  title: PropTypes.string,
};



const mapStateToProps = (state) => ({
  isOpen: state.modal.isOpen,
  id: state.modal.id,
  title: state.modal.title,
  isReadOnly: state.modal.isReadOnly,
  entity: state.people.entity,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
  get: (id) => dispatch(getSupplierRequest(id)),
  save: (data) => dispatch(saveSupplierRequest(data)),
  update: (data) => dispatch(updateSupplierRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SupplierModal);