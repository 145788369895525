import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    error: null,
    comboData: []
};

const SupplierSlice = createSlice({
    name: 'supplier',
    initialState,
    reducers: {
        saveSupplierRequest(state) {
            state.loading = true;
            state.error = null;
        },
        saveSupplierSuccess(state, action) {
            state.loading = false;
            state.data.push(action.payload);
        },
        saveSupplierFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updateSupplierRequest(state) {
            state.loading = true;
            state.error = null;
        },
        updateSupplierSuccess(state, action) {
            state.loading = false;
            state.entity = {};
            const index = state.data.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
                state.data[index] = action.payload;
            }
        },
        updateSupplierFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        removeSupplierRequest(state) {
            state.loading = true;
            state.error = null;
        },
        removeSupplierSuccess(state, action) {
            state.loading = false;
            state.data = state.data.filter(item => item.id !== action.payload);
        },
        removeSupplierFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getAllSuppliersRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getAllSuppliersSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        getAllSuppliersFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getSupplierRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getSupplierSuccess(state, action) {
            state.loading = false;
            state.data = state.data.map(item => item.id === action.payload ? action.payload : item);
        },
        getSupplierFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getSupplierComboRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getSupplierComboSuccess(state, action) {
            state.loading = false;
            state.comboData = action.payload;
        },
        getSupplierComboFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const {
    saveSupplierRequest,
    saveSupplierSuccess,
    saveSupplierFailure,
    updateSupplierRequest,
    updateSupplierSuccess,
    updateSupplierFailure,
    removeSupplierRequest,
    removeSupplierSuccess,
    removeSupplierFailure,
    getAllSuppliersRequest,
    getAllSuppliersSuccess,
    getAllSuppliersFailure,
    getSupplierRequest,
    getSupplierSuccess,
    getSupplierFailure,
    getSupplierComboRequest,
    getSupplierComboSuccess,
    getSupplierComboFailure
} = SupplierSlice.actions;

export default SupplierSlice.reducer;
