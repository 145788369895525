import { call, put, takeLatest } from 'redux-saga/effects';
import {
    saveRequest,
    saveSuccess,
    saveFailure,
    updateRequest,
    updateSuccess,
    updateFailure,
    removeRequest,
    removeSuccess,
    removeFailure,
    getAllRequest,
    getAllSuccess,
    getAllFailure,
    getRequest,
    getSuccess,
    getFailure,
    getComboRequest,
    getComboSuccess,
    getComboFailure
} from './slice';
import { closeModal } from '../modal/slice';
import toast from 'react-hot-toast';
import { get, getAll, post, update, remove } from '../../helpers/apiHelpers';

function* saveSaga(action) {
    try {
        const response = yield call(post, 'Units', action.payload);
        yield put(saveSuccess(response));
        yield put(closeModal());
        toast.success('Registro guardado correctamente.');
    } catch (error) {
        yield put(saveFailure(error.message));
        toast.error('Ocurrió un error al guardar el registro.');
    }
}

function* updateSaga(action) {
    try {
        const response = yield call(update, 'Units', action.payload);
        yield put(updateSuccess(response));
        yield put(closeModal());
        toast.success('Registro actualizado correctamente.');
    } catch (error) {
        yield put(updateFailure(error.message));
        toast.error('Ocurrió un error al actualizar el registro.');
    }
}

function* removeSaga(action) {
    try {
        yield call(remove, 'Units', action.payload);
        yield put(removeSuccess(action.payload));
        toast.success('Registro eliminado correctamente.');
    } catch (error) {
        yield put(removeFailure(error.message));
        toast.error('Ocurrió un error al eliminar el registro.');
    }
}

function* getAllSaga() {
    try {
        const response = yield call(getAll, 'Units');
        yield put(getAllSuccess(response));
    } catch (error) {
        yield put(getAllFailure(error.message));
    }
}

function* getSaga(action) {
    try {
        const response = yield call(get, 'Units', action.payload);
        yield put(getSuccess(response));
    } catch (error) {
        yield put(getFailure(error.message));
    }
}

function* getComboSaga() {
    try {
        const response = yield call(get, 'Units/combo');
        yield put(getComboSuccess(response));
    } catch (error) {
        yield put(getComboFailure(error.message));
    }
}

export default function* UnitsSaga() {
    yield takeLatest(saveRequest.type, saveSaga);
    yield takeLatest(updateRequest.type, updateSaga);
    yield takeLatest(removeRequest.type, removeSaga);
    yield takeLatest(getAllRequest.type, getAllSaga);
    yield takeLatest(getRequest.type, getSaga);
    yield takeLatest(getComboRequest.type, getComboSaga);
}
