import React, { useState } from "react";
import PropTypes from "prop-types";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useFormik } from "formik";
import { connect, useDispatch } from "react-redux";
import { loginRequest } from "../../../core/redux/account/slice";

const Signin = ({
  loading,
}) => {
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      dispatch(loginRequest({values, navigate}));
    }
  })
  const route = all_routes;
  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-content">
            <form>
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                </div>
                <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt />
                </Link>
                <div className="login-userheading">
                  <h3>Iniciar Sesión</h3>
                  <h4>
                    Ingrese al portal de gestión de su negocio
                  </h4>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Email / Usuario</label>
                  <div className="form-addons">
                    <input
                      name="username"
                      onChange={formik.handleChange}
                      type="text" className="form-control" />
                    <ImageWithBasePath
                      src="assets/img/icons/mail.svg"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Contraseña</label>
                  <div className="pass-group">
                    <input
                      name="password"
                      onChange={formik.handleChange}
                      type={isPasswordVisible ? "text" : "password"}
                      className="pass-input form-control"
                    />
                    <span
                      className={`fas toggle-password ${isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                        }`}
                      onClick={togglePasswordVisibility}
                    ></span>
                  </div>
                </div>
                <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <div className="custom-control custom-checkbox">
                        <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                          <input type="checkbox" className="form-control" />
                          <span className="checkmarks" />
                          Recuerdame
                        </label>
                      </div>
                      <div className="text-end">
                        <Link className="forgot-link" to={route.forgotPassword}>
                          Olvidaste la contraseña?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-login">
                  <Link onClick={formik.handleSubmit} className={loading ?'btn btn-login disabled' : 'btn btn-login'}>
                    Acceder
                  </Link>
                </div>
                <div className="signinform">
                  <h4>
                    ¿No tienes cuenta?
                    <Link to={route.register} className="hover-a">
                      {" "}
                      Crear cuenta
                    </Link>
                  </h4>
                </div>
                {/* <div className="form-setlogin or-text">
                  <h4>ó</h4>
                </div> */}
                <div className="form-sociallink">
                  {/* <ul className="d-flex">
                    <li>
                      <Link to="#" className="facebook-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/facebook-logo.svg"
                          alt="Facebook"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/icons/google.png"
                          alt="Google"
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="apple-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/apple-logo.svg"
                          alt="Apple"
                        />
                      </Link>
                    </li>
                  </ul> */}
                  <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                    <p>Copyright © {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

Signin.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
})
export default connect(mapStateToProps, { loginRequest })(Signin);
