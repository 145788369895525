import React, { useEffect } from 'react'
import { FieldArray, FormikProvider, useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { getRequest, saveRequest, updateRequest, clearEntity } from "../../../redux/variationtypes/slice";
import { closeModal } from '../../../redux/modal/slice';
import { connect } from 'react-redux';
import * as Yup from "yup";
// save, update, 
const Form = ({ save, update, loading, id, isOpen, closeModal, clearEntity, get, entity, isReadOnly, title }) => {

    const formik = useFormik({
        validationSchema: Yup.object().shape({
            name: Yup.string().required("El nombre es requerido"),
            variations: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required("El nombre es requerido"),
                })
            ),
        }),
        enableReinitialize: true,
        initialValues: {
            name: entity.name || '',
            active: entity.active || false,
            variations: entity.variations || [{ name: ''}],
        },
        onSubmit: (values) => {
            if (loading) return;

            console.log(values);
            if (id) {
                update({ id, ...values });
            } else {
                save(values);
            }
        },
    });

    useEffect(() => {
        if (id) {
            get(id);
        }
    }, [id, get]);

    const handleCancel = () => {
        closeModal();
        clearEntity();
    };


    console.log(entity);
   

    return (
        <Modal show={isOpen} onHide={handleCancel}>
            <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                        <h4>{title}</h4>
                    </div>
                    <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCancel}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body custom-modal-body">
                    <FormikProvider value={formik}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Nombre</label>
                                <input
                                    name="name"
                                    readOnly={isReadOnly}
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                />
                                {formik.errors.name && formik.touched.name && (
                                    <p className="text-danger">{formik.errors.name}</p>
                                )}
                            </div>
                            
                            <FieldArray name="variations">
                                {({ push, remove }) => (
                                    <div>
                                        {formik.values.variations.map((variation, index) => (
                                            <div key={index} className="d-flex align-items-center mb-3">
                                                <input
                                                    name={`variations[${index}].name`}
                                                    type="text"
                                                    className="form-control me-2"
                                                    placeholder="Variante"
                                                    onChange={formik.handleChange}
                                                    value={variation.name}
                                                />
                                                {formik.errors.variations?.[index]?.name && formik.touched.variations?.[index]?.name && (
                                                    <p className="text-danger">{formik.errors.variations[index].name}</p>
                                                )}
                                                
                                                <button
                                                    type="button"
                                                    className="btn btn-danger ms-2"
                                                    onClick={() => remove(index)}
                                                >
                                                    Eliminar
                                                </button>
                                            </div>
                                        ))}
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => push({ name: '',})}
                                        >
                                            Agregar variación
                                        </button>
                                    </div>
                                )}
                            </FieldArray>
                                <hr />
                            <div className="mb-0">
                            <div className="status-toggle modal-status d-flex justify-content-between align-items-center">
                                <span className="status-label">Estado </span>
                                <input
                                    name='active'
                                    checked ={formik.values.active}
                                    readOnly={isReadOnly}
                                    type="checkbox"
                                    id="active"
                                    className="check"
                                    defaultChecked={'true'}
                                />
                                <label onClick={ ()=> formik.setFieldValue('active',!formik.values.active)} htmlFor="active" className="checktoggle" />
                            </div>
                        </div>
                            <div className="modal-footer-btn">
                                <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                    onClick={handleCancel}
                                >
                                    Cancelar
                                </button>
                                <button onClick={formik.handleSubmit} type="button" className="btn btn-submit">
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </FormikProvider>
                </div>
            </div>
        </Modal>
    );
};


Form.propTypes = {
    handleSave: PropTypes.func,
    loading: PropTypes.bool,
    isOpen: PropTypes.bool,
    id: PropTypes.number,
    closeModal: PropTypes.func,
    get: PropTypes.func,
    entity: PropTypes.object,
    save: PropTypes.func,
    update: PropTypes.func,
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    clearEntity: PropTypes.func,
};



const mapStateToProps = (state) => ({
    isOpen: state.modal.isOpen,
    id: state.modal.id,
    title: state.modal.title,
    isReadOnly: state.modal.isReadOnly,
    entity: state.variations.entity,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    clearEntity: () => dispatch(clearEntity()),
    get: (id) => dispatch(getRequest(id)),
    save: (data) => dispatch(saveRequest(data)),
    update: (data) => dispatch(updateRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form);
