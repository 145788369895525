import toast from 'react-hot-toast';

export default function setupAxios(axios, store) {
  // Set the base URL for axios
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  // Request interceptor
  axios.interceptors.request.use(
    config => {
      // console.log("store",config);
      const user = store.getState().auth?.user;
      const token = user?.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response.use(
    response => response,
    error => {
      const { status, data } = error.response;
      if (status === 400) {
        if (data?.errors) {
          // Handle validation errors
          Object.keys(data.errors).forEach(key => {
            data.errors[key].forEach(message => {
              toast.error(message, { duration: 4000 });
            });
          });
        } else if (data?.message) {
          // Handle custom exceptions
          toast.error(data.message, { duration: 4000 });
        }
      }
      return Promise.reject(error);
    }
  );
}
