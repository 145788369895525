import { all } from 'redux-saga/effects';
import authSaga from './account/sagas'; // Import the saga
import PeopleSaga from './people/saga';
import SupplierSaga from './supplier/saga';
import CategorySaga from './category/saga';
import BrandSaga from './brand/saga';
import UnitSaga from './units/saga';
import VariationTypesSaga from './variationtypes/saga';
import MainProductsSage from './mainproducts/saga';
import TaxesRateSaga from './taxes/saga';   
import StockSaga from './stock/saga';
import SystemSettingsSaga from './systemsettings/saga';
import QuotationSaga from './quotations/saga';
import SalesSaga from './sales/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        PeopleSaga(),
        SupplierSaga(),
        CategorySaga(),
        BrandSaga(),
        UnitSaga(),
        VariationTypesSaga(),
        MainProductsSage(),
        TaxesRateSaga(),
        StockSaga(),
        SystemSettingsSaga(),
        QuotationSaga(),
        SalesSaga(),
        
    ]);
}