// CustomerSelect.jsx
import React, { useEffect } from 'react';
import Select from 'react-select';
import { PlusCircle } from 'react-feather';
import { connect } from 'react-redux';
import { openModal } from '../../../core/redux/modal/slice';
import { ModalType } from '../../../core/modals/types';
import { getPeopleComboRequest } from '../../../core/redux/people/slice';
import PropTypes from 'prop-types';

const CustomerSelect = ({getCustomers, customers, onChange, addClient, value, errors }) => {
  
    useEffect(() => {
        getCustomers();
    }, []);

    return (
    <div className="input-blocks">
      <label>Cliente</label>
      <div className="row">
        <div className="col-lg-10 col-sm-10 col-10">
          <Select
            name="customerId"
            onChange={onChange}
            classNamePrefix="react-select"
            options={customers}
            defaultValue={value}
            noOptionsMessage={() => 'No se encontraron resultados'}
            placeholder="Seleccione cliente"
          />
        </div>
        <div className="col-lg-2 col-sm-2 col-2 ps-0">
          <div onClick={addClient} className="add-icon">
            <span className="choose-add">
              <PlusCircle className="plus" />
            </span>
          </div>
        </div>
        {errors? (
          <span className="text-danger">{errors}</span>
        ) : null}
      </div>
    </div>
  );
};



const mapStateToProps = (state) => ({
    customers: state.people?.comboData ?? [],
  });
  
  const mapDispatchToProps = (dispatch) => ({
    addClient: () => dispatch(openModal({ type: ModalType.CUSTOMER, title: 'Agregar Cliente' })),
    getCustomers: () => dispatch(getPeopleComboRequest({ type: 'customer' })),
  
  });
  
  CustomerSelect.propTypes = {
    addClient: PropTypes.func,
    getCustomers: PropTypes.func,
    customers: PropTypes.array,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    value: PropTypes.string,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelect);
  