import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setToogleHeader } from "../../../core/redux/action";
import TaxRateForm from "../../../core/modals/settings/Taxes/TaxRateForm";
import Table from "../../../core/pagination/datatable";
import { openModal } from '../../../core/redux/modal/slice';
// import { taxRatesData } from "../../../core/json/taxRates";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getAllRequest, removeRequest} from '../../../core/redux/taxes/slice';
import PropTypes from "prop-types";
import { ModalType } from "../../../core/modals/types";
const TaxRates = ({getAll, remove,dataSource, openModal}) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);

  const renderRefreshTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Refresh
    </Tooltip>
  );
  const renderCollapseTooltip = (props) => (
    <Tooltip id="refresh-tooltip" {...props}>
      Collapse
    </Tooltip>
  );
  const datas = dataSource?.items||[];
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Estás seguro?",
      text: "No podrás revertir esta acción!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        remove(id);
      } else {
        MySwal.close();
      }
    });
  };

  const filteredData = datas.filter((entry) => {
    return Object.keys(entry).some((key) => {
      return String(entry[key])
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  });
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Valor%",
      dataIndex: "rate",
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Creado",
      dataIndex: "createdOn",
      sorter: (a, b) => new Date(a.createdOn) - new Date(b.createdOn),
    },
    {
      title: "Acciones",
      dataIndex: "id",
      render: (id) => (
        <div className="edit-delete-action action-table-data">
          <Link
            className="me-2 p-2"
            onClick={() => dispatch(openModal({ id: id, title: 'Editar Impuesto', type: ModalType.TAX }))}
          >
            <i data-feather="edit" className="feather-edit" />
          </Link>
          <Link className="confirm-text p-2" href="#" onClick={()=>showConfirmationAlert(id)}>
            <i data-feather="trash-2" className="feather-trash-2" />
          </Link>
        </div>
      ),
    },
  ];


  useEffect(() => {
    getAll();
  }, [])
  return (
    <div>
      <div className="page-wrapper">
        <div className="content settings-content">
          <div className="page-header settings-pg-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Configuraciones</h4>
                <h6>Administre la configuración de su sistema</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>
                  <Link data-bs-toggle="tooltip" data-bs-placement="top">
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={data ? "active" : ""}
                    onClick={() => {
                      dispatch(setToogleHeader(!data));
                    }}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="settings-wrapper d-flex">
                <div className="settings-page-wrap w-50">
                  <div className="setting-title">
                    <h4>Impuestos</h4>
                  </div>
                  <div className="page-header bank-settings justify-content-end">
                    <div className="page-btn">
                      <Link
                        to="#"
                        className="btn btn-added"
                        onClick={() => dispatch(openModal({ title: 'Agregar Impuesto', type: ModalType.TAX }))}
                      >
                        <PlusCircle className="me-2" />
                        Agregar Impuesto
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card table-list-card">
                        <div className="card-body">
                          <div className="table-top">
                            <div className="search-set">
                              <div className="search-input">
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder="Search"
                                  aria-controls="DataTables_Table_0"
                                  value={searchText}
                                  onChange={handleSearch}
                                />
                                <Link to className="btn btn-searchset">
                                  <i
                                    data-feather="search"
                                    className="feather-search"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <Table
                              columns={columns}
                              dataSource={filteredData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TaxRateForm />
    </div>
  );
};


const mapStateToProps = (state) => ({
  dataSource: state.taxes?.data ?? {},

});

const mapDispatchToProps = (dispatch) => ({
  remove: (id) => dispatch(removeRequest(id)),
  getAll: (filter) => dispatch(getAllRequest(filter)),
  openModal: (id) => dispatch(openModal(id)),
});

TaxRates.propTypes = {
  dataSource: PropTypes.array.isRequired,
  remove: PropTypes.func,
  getAll: PropTypes.func,
  openModal: PropTypes.func,
  

};

export default connect(mapStateToProps, mapDispatchToProps)(TaxRates);
