import React from 'react';
import { FieldArray, Field, FormikProvider, Form } from 'formik';
import PropTypes from 'prop-types';
import NumericInput from '../../components/NumericInput';
import { useEffect } from 'react';
const Products = ({ formik, isVariant, variations, taxes, readOnly }) => {
  // const [finalPrice, setFinalPrice] = useState(0);

  const handleSelectTax = (taxId, index) => {
    const taxRate = taxes.find(x => x.id == taxId)?.rate || 0;
    formik.setFieldValue(`products[${index}].taxId`, taxId);
    updateFinalPrice(index, taxRate, true );
  };

  const updateFinalPrice = (index, taxRate, taxSelected) => {
    taxRate = taxRate || 0;
    const product = formik.values.products[index];
    const purchasePrice = parseFloat(product.purchasePrice || 0);
    const profitMargin = parseFloat(product.profitMargin || 0);
  
    const salePrice = taxSelected ? parseFloat(product.salePrice) : purchasePrice + (purchasePrice * (profitMargin / 100));
    const finalPrice = salePrice + (salePrice * (taxRate / 100));
  
    console.log('salePrice', salePrice);
    formik.setFieldValue(`products[${index}].salePrice`, salePrice.toFixed(2));
    formik.setFieldValue(`products[${index}].finalPrice`, finalPrice.toFixed(2));
  };

useEffect(() => {console.log(formik.values)}, [formik.values]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <FieldArray name="products">
          {({ push, remove, }) => {
            // Add an initial product if the array is empty
            if (formik.values?.products?.length === 0) {
              push({
                variationId: undefined,
                quantity: 0,
                purchasePrice: 0.00,
                salePrice: 0.00,
                taxId: null, // Default to Inclusive
                profitMargin: 0.00,
                quantityAlert: 0.00,
                name: formik.values.name
              });
            }

            return (
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      {isVariant && <th style={{ width: '10%' }}>Variante</th>}
                      <th style={{ width: '10%' }}>Cantidad Inicial </th>
                      <th style={{ width: '10%' }}>$ Compra <small className='text-red'>(sin imp.)</small></th>
                      <th style={{ width: '10%' }}>% Retabilidad</th>
                      <th style={{ width: '15%' }}>$ Venta <small className='text-red'>(sin imp.)</small></th>
                      <th style={{ width: '10%' }}>Impuesto</th>
                      <th style={{ width: '10%' }}>$ Venta <small className='text-red'>(con imp.)</small></th>
                      <th style={{ width: '10%' }}>Alerta</th>
                      {isVariant && <th>Acciones</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.products?.map((product, index) => (
                      <tr key={index}>
                        {
                          isVariant && (
                            <td>
                              <Field
                                name={`products[${index}].variationId`}
                                className="form-control"
                                as="select">
                                <option value="null">Seleccione</option>
                                {variations?.map((variation, key) => (
                                  <option value={variation.value} key={key}>{variation.label}</option>
                                ))}
                              </Field>
                            </td>
                          )
                        }
                        <td>
                          <Field
                            readOnly={readOnly}
                            disabled={readOnly}
                            name={`products[${index}].quantity`}
                            type="number"
                            className="form-control" />
                        </td>
                        <td>
                          <NumericInput
                            onChange={(v)=>formik.setFieldValue(`products[${index}].purchasePrice`,v.floatValue)}
                            name={`products[${index}].purchasePrice`}
                            className="form-control"
                            onBlur={() => updateFinalPrice(index)}
                          />
                        </td>
                        <td>
                          <Field
                            name={`products[${index}].profitMargin`}
                            type="number"
                            className="form-control"
                            onBlur={() => updateFinalPrice(index)}
                          />
                        </td>
                        <td>
                          <NumericInput
                            name={`products[${index}].salePrice`}
                            className="form-control"
                            value={formik.values.products[index].salePrice}
                          />
                        </td>
                        <td>
                          <Field
                            as="select"
                            className="form-control"
                            onChange={(e) => handleSelectTax(e.target.value, index)}
                            name={`products[${index}].taxId`}
                          >
                            <option>Seleccione</option>
                            {taxes && taxes.map((tax, key) => (<option key={key} value={tax.id}>{tax.name}</option>))}
                          </Field>
                        </td>
                        <td>
                          <NumericInput
                            disabled
                            readOnly
                            name={`products[${index}].finalPrice`}
                            className="form-control"
                            value={formik.values.products[index].finalPrice}
                          />
                        </td>
                        <td>
                          <Field
                            name={`products[${index}].quantityAlert`}
                            type="number"
                            className="form-control"
                          />
                        </td>
                        {isVariant &&
                          <td>
                            {formik.values.products.length > 1 && (
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => remove(index)}
                              >
                                X
                              </button>
                            )} {' '}
                            {
                              formik.values.products.length - 1 === index && (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => push({
                                    name: formik.values.name,
                                    quantity: 0.00,
                                    purchasePrice: 0.00,
                                    salePrice: 0.00,
                                    TaxId: null,
                                    discountType: 0,
                                    discountValue: 0.00,
                                    profitMargin: 0.00,
                                    quantityAlert: 0.00,
                                  })}
                                >
                                  +
                                </button>
                              )
                            }
                          </td>}
                      </tr>
                    ))}

                  </tbody>
                </table>
              </div>
            );
          }}
        </FieldArray>
      </Form>
    </FormikProvider>
  );
};

Products.propTypes = {
  formik: PropTypes.object.isRequired,
  isVariant: PropTypes.bool,
  variations: PropTypes.array,
  taxes: PropTypes.array,
  readOnly: PropTypes.bool,
};

export default Products;