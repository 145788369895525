import { ChevronUp, PlusCircle, RotateCcw, User } from 'feather-icons-react/build/IconComponents'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { setToogleHeader } from '../../../core/redux/action';
import SettingsSidebar from '../settingssidebar';
import { Settings } from 'react-feather';

const GeneralSettings = () => {

    const dispatch = useDispatch();
    const data = useSelector((state) => state.toggle_header);

    const renderRefreshTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Refresh
        </Tooltip>
    );
    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Collapse
        </Tooltip>
    )
    return (
        <div>
            <div className="page-wrapper">
                <div className="content settings-content">
                    <div className="page-header settings-pg-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Settings</h4>
                                <h6>Manage your settings on portal</h6>
                            </div>
                        </div>
                        <ul className="table-top-head">
                            <li>
                                <OverlayTrigger placement="top" overlay={renderRefreshTooltip}>

                                    <Link data-bs-toggle="tooltip" data-bs-placement="top">
                                        <RotateCcw />
                                    </Link>
                                </OverlayTrigger>
                            </li>
                            <li>
                                <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>

                                    <Link
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        id="collapse-header"
                                        className={data ? "active" : ""}
                                        onClick={() => { dispatch(setToogleHeader(!data)) }}
                                    >
                                        <ChevronUp />
                                    </Link>
                                </OverlayTrigger>
                            </li>

                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <ul className="nav nav-pills mb-3" role="tablist">
                                        <li className="nav-item dropdown">
                                        <Settings />
                                            <Link
                                                className="nav-link dropdown-toggle"
                                                data-bs-toggle="dropdown"
                                                to="#"
                                                role="button"
                                                aria-expanded="false"
                                            >
                                                Configuración General
                                            </Link>
                                            <ul className="dropdown-menu">
                                                <li>
                                                    <Link
                                                        className="dropdown-item"
                                                        data-bs-toggle="tab"
                                                        role="tab"
                                                        aria-current="page"
                                                        to="#pill-profile"
                                                        aria-selected="false"
                                                    >
                                                        Perfil
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="dropdown-item"
                                                        data-bs-toggle="tab"
                                                        role="tab"
                                                        aria-current="page"
                                                        to="#pill-wishlist"
                                                        aria-selected="false"
                                                    >
                                                        Seguridad
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="#">
                                                        Notificaciones
                                                    </Link>
                                                </li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li>
                                                    <Link className="dropdown-item" to="#">
                                                        Recently Viewed
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                role="tab"
                                                aria-current="page"
                                                to="#pill-orders"
                                                aria-selected="false"
                                            >
                                                Orders
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link disabled">Offers</Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div
                                            className="tab-pane text-muted"
                                            id="pill-profile"
                                            role="tabpanel"
                                        >
                                            <div className="settings-page-wrap">
                                                <form>
                                                    <div className="setting-title">
                                                        <h4>Configuracion de perfil</h4>
                                                    </div>
                                                    <div className="card-title-head">
                                                        <h6>
                                                            <span>

                                                                <User className="feather-chevron-up" />
                                                            </span>
                                                            Información personal
                                                        </h6>
                                                    </div>
                                                    <div className="profile-pic-upload">
                                                        <div className="profile-pic">
                                                            <span>

                                                                <PlusCircle className="plus-down-add" />
                                                                Foto de perfil
                                                            </span>
                                                        </div>
                                                        <div className="new-employee-field">
                                                            <div className="mb-0">
                                                                <div className="image-upload mb-0">
                                                                    <input type="file" />
                                                                    <div className="image-uploads">
                                                                        <h4>Cambiar Imagen</h4>
                                                                    </div>
                                                                </div>
                                                                <span>
                                                                    Para una mejor visualización, te recomendamos que subas una imagen de 400x400 px
                                                                    5 MB Máximo.
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label">First Name</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label">Last Name</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label">User Name</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label">Phone Number</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label">Email</label>
                                                                <input type="email" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-title-head">
                                                        <h6>
                                                            <span>
                                                                <i data-feather="map-pin" className="feather-chevron-up" />
                                                            </span>
                                                            Our Address
                                                        </h6>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label className="form-label">Address</label>
                                                                <input type="email" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-3 col-lg-4 col-md-3">
                                                            <div className="mb-3">
                                                                <label className="form-label">Country</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-3 col-lg-4 col-md-3">
                                                            <div className="mb-3">
                                                                <label className="form-label">State / Province</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-3 col-lg-4 col-md-3">
                                                            <div className="mb-3">
                                                                <label className="form-label">City</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-3 col-lg-4 col-md-3">
                                                            <div className="mb-3">
                                                                <label className="form-label">Postal Code</label>
                                                                <input type="text" className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-end settings-bottom-btn">
                                                        <button type="button" className="btn btn-cancel me-2">
                                                            Cancel
                                                        </button>
                                                        <Link to="#" className="btn btn-submit">
                                                            Save Changes
                                                        </Link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane text-muted"
                                            id="pill-profile"
                                            role="tabpanel"
                                        >
                                            profile hotel deals can help you live a better life.{" "}
                                            <b>How celebrity cruises</b> aren&apos;t as bad as you think. How
                                            cultural solutions can help you predict the future. How to cheat
                                            at dog friendly hotels and get away with it.
                                        </div>
                                        <div
                                            className="tab-pane text-muted"
                                            id="pill-orders"
                                            role="tabpanel"
                                        >
                                            Lorem Ipsum is simply dummy text of the printing and typesetting
                                            industry. Lorem Ipsum has been the industry&apos;s{" "}
                                            <b>standard dummy text ever since the 1500s</b>, when an unknown
                                            printer took a galley of type and scrambled it to make a type
                                            specimen book. It has survived not only five centuries, but also
                                            the leap into electronic typesetting, remaining essentially
                                            unchanged. It was popularised in the 1960s with the release of
                                            Letraset sheets containing Lorem Ipsum passages, and more
                                            recently with desktop publishing software like Aldus PageMaker
                                            including versions of Lorem Ipsum
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="settings-wrapper d-flex">
                                <SettingsSidebar />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GeneralSettings
