import { call, put, takeLatest } from 'redux-saga/effects';
import {
    saveRequest,
    saveSuccess,
    saveFailure,
    updateRequest,
    updateSuccess,
    updateFailure,
    removeRequest,
    removeSuccess,
    removeFailure,
    getAllRequest,
    getAllSuccess,
    getAllFailure,
    getRequest,
    getSuccess,
    getFailure,
    getComboRequest,
    getComboSuccess,
    getComboFailure,
    searchSuccess,
    searchFailure,
    searchRequest,
    scanCodeRequest,
    scanCodeSuccess,
    scanCodeFailure
} from './slice';
import { closeModal } from '../modal/slice';
import toast from 'react-hot-toast';
import { get, getAll, post, update, remove } from '../../helpers/apiHelpers';

function* saveSaga(action) {
    const savingId = toast.loading('Guardando Producto...');
    try {
        const response = yield call(post, 'MainProducts', action.payload);
        yield put(saveSuccess(response));
        yield put(closeModal());
        toast.success('Registro guardado correctamente.',{id:savingId});
    } catch (error) {
        yield put(saveFailure(error.message));
        toast.error('Ocurrió un error al guardar el registro.',{id:savingId});
    }
}



function* updateSaga(action) {
    try {
        console.log(action.payload)
        const response = yield call(update, 'MainProducts', action.payload);
        yield put(updateSuccess(response));
        yield put(closeModal());
        toast.success('Registro actualizado correctamente.');
    } catch (error) {
        yield put(updateFailure(error.message));
        toast.error('Ocurrió un error al actualizar el registro.');
    }
}

function* removeSaga(action) {
    const removingId = toast.loading('Eliminando Producto...');
    try {
        console.log(action.payload) 
        yield call(remove, 'MainProducts', action.payload);
        yield put(removeSuccess(action.payload));
        toast.dismiss(removingId);
        toast.success('Registro eliminado correctamente.');
    } catch (error) {
        yield put(removeFailure(error.message));
        toast.dismiss(removingId);
        toast.error('Ocurrió un error al eliminar el registro.');
    }
}

function* searchSaga({ payload }) {
    try {
        const response = yield call(getAll, 'MainProducts/search',{term:payload});
        yield put(searchSuccess(response));
    } catch (error) {
        yield put(searchFailure(error.message));
    }
}
function* scanCodeSaga({ payload }) {
    try {
        const response = yield call(getAll, 'MainProducts/scancode',{code:payload});
        yield put(scanCodeSuccess(response));
    } catch (error) {
        yield put(scanCodeFailure(error.message));
    }
}

function* getAllSaga({ payload }) {
    console.log(payload)
    try {
        const response = yield call(getAll, 'MainProducts',payload);
        yield put(getAllSuccess(response));
    } catch (error) {
        yield put(getAllFailure(error.message));
    }
}

function* getSaga(action) {
    try {
        const response = yield call(get, 'MainProducts', action.payload);
        yield put(getSuccess(response));
    } catch (error) {
        yield put(getFailure(error.message));
    }
}

function* getComboSaga() {
    try {
        const response = yield call(get, 'MainProducts/combo');
        yield put(getComboSuccess(response));
    } catch (error) {
        yield put(getComboFailure(error.message));
    }
}

export default function* MainProductsSaga() {
    yield takeLatest(searchRequest.type, searchSaga);
    yield takeLatest(scanCodeRequest.type, scanCodeSaga);
    yield takeLatest(saveRequest.type, saveSaga);
    yield takeLatest(updateRequest.type, updateSaga);
    yield takeLatest(removeRequest.type, removeSaga);
    yield takeLatest(getAllRequest.type, getAllSaga);
    yield takeLatest(getRequest.type, getSaga);
    yield takeLatest(getComboRequest.type, getComboSaga);
}
