import React, { useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginRequest } from "../../core/redux/account/slice";
import PropTypes from "prop-types";
import { update } from "../../core/helpers/apiHelpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { all_routes } from "../../Router/all_routes";
import toast from "react-hot-toast";
const Profile = ({ user }) => {
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState(user?.avatar?? 'assets/img/avatar/no-image.png');


  const validationSchema = Yup.object({
    givenName: Yup.string().required("El nombre es requerido"),
    familyName: Yup.string().required("El apellido es requerido"),
    // phone: Yup.string().required("El telefono es requerido"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      givenName: '',
      familyName: '',
      // phone: "",
      ...user
    },
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('id', user.id);
      formData.append('givenName', values.givenName);
      formData.append('familyName', values.familyName);
      // formData.append('phone', values.phone);
      // Append other form values as needed
      if (profileImage) {
        formData.append('avatar', profileImage);
      }

      await updateUser(formData);
    }
  });

  const updateUser = async (data) => {
    const toastId = toast.loading('Guardando cambios...');
    await update('user/profile', data).then(() => {
        // toast.dismiss(toastId);
        toast.success('Cambios guardados con éxito', { id: toastId });
    }).catch(() => { 
      toast.error('Ocurrió un error al guardar los cambios', { id: toastId });
     });
  }

  const handleProfileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Perfil</h4>
            <h6>Perfil de usuario</h6>
          </div>
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="profile-set">
              <div className="profile-head"></div>
              <div className="profile-top">
                <div className="profile-content">
                  <div className="profile-contentimg">
                    {profileImagePreview && (
                        <img src={profileImagePreview} alt="Profile Preview"  />
                    )}
                    <div className="profileupload">
                      <input onChange={handleProfileUpload} type="file" id="imgInp" />
                      <Link to="#">
                        <ImageWithBasePath
                          src="assets/img/icons/edit-set.svg"
                          alt="img"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="profile-contentname">
                    <h2>{user.givenName}</h2>
                    <h4>Actualiza tus datos personales y tu foto.</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label"><span>* </span> Nombre</label>
                  <input
                    type="text"
                    className="form-control"
                    name="givenName"
                    onChange={formik.handleChange}
                    value={formik.values.givenName}
                  />
                  {formik.errors.givenName && formik.touched.givenName && (<span className="text-danger">{formik.errors.givenName}</span>)}
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label"><span>* </span> Apellido</label>
                  <input
                    type="text"
                    className="form-control"
                    name="familyName"
                    onChange={formik.handleChange}
                    value={formik.values.familyName}
                  />
                  {formik.errors.familyName && formik.touched.familyName && (<span className="text-danger">{formik.errors.familyName}</span>)}
                </div>
              </div>
              {/* <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Teléfono</label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                  {formik.errors.phone && formik.touched.phone && (<span className="text-danger">{formik.errors.phone}</span>)}
                </div>
              </div> */}
              {/* <div className="col-lg-6 col-sm-12">
                <div className="input-blocks">
                  <label className="form-label">Password</label>
                  <div className="pass-group">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="pass-input form-control"
                    />
                    <span
                      className={`fas toggle-password ${
                        isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePasswordVisibility}
                    ></span>
                  </div>
                </div>
              </div> */}
              <div className="col-12">
                <button type="button" onClick={formik.handleSubmit} className="btn btn-submit me-2">
                  Guardar
                </button>
                <Link to={all_routes.dashboard} className="btn btn-cancel">
                  Cancelar
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
})

Profile.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps, { loginRequest })(Profile);
