import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    type: undefined,
    isOpen: false,
    isReadOnly: false,
    title: '',
    id: undefined,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state, action) {
            state.isOpen = true;
            state.id = action.payload.id;
            state.isReadOnly = action.payload.isReadOnly ?? false; // Ensure isReadOnly is set
            state.title = action.payload.title;
            state.type = action.payload?.type
        },
        closeModal(state) {
            state.isOpen = false;
            state.id = undefined;
            state.isReadOnly = false;
            state.type = undefined;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;